<ng-container *ngIf="loading; else content">
  <mat-spinner class="center-spinner"></mat-spinner>
</ng-container>

<ng-template #content>
  <div class="form-container">
    <h1 class="mb-3">{{ 'teams.integrations.slack_config' | translate }}</h1>
    <form [formGroup]="configForm" (ngSubmit)="onSubmit(configForm)" class="config-form">
      <div class="form-grid">
        <!-- Basic Information Section -->
        <div class="form-section">
          <h2 class="section-header">{{ 'teams.integrations.basic_info' | translate }}</h2>

          <!-- Config Name -->
          <app-input formControlName="name"
                     [config]="{ id: 'name', label: 'teams.integrations.name', class: 'w-100' }"
                     [errors]="configForm.get('name').errors"
                     [required]="true"
          ></app-input>

          <!-- Token -->
          <app-input formControlName="token"
                     [config]="{ id: 'token', label: 'teams.integrations.token', class: 'w-100' }"
                     [errors]="configForm.get('token').errors"
                     [required]="true"
          ></app-input>

          <!-- Channel ID -->
          <app-input formControlName="channel_id"
                     [config]="{ id: 'channel_id', label: 'teams.integrations.channel_id', class: 'w-100' }"
                     [errors]="configForm.get('channel_id').errors"
                     [required]="true"
          ></app-input>

          <!-- Private Discussion Toggle -->
          <mat-checkbox formControlName="is_private_discussion">
            {{ 'teams.integrations.set_private_discussion' | translate }}
          </mat-checkbox>

          <!-- App Icon -->
          <div class="icon-section">
            <h3>{{ 'form.teams.integrations.preview_app_icon.label' | translate }}</h3>
            @if (previewApp) {
              <div class="mb-3 preview-container">
                <img [src]="previewApp" alt="Uploaded Icon" class="uploaded-icon-preview">
              </div>
            }
            <div class="avatar-upload-container__upload mb-3" (click)="fileInputApp.click()">
              <div class="avatar-upload-container__upload__link">
                <mat-icon>upload</mat-icon>
                <span>{{ 'form.teams.integrations.app_icon.label' | translate }}</span>
              </div>
              <input hidden type="file" accept=".jpg, .png, .bmp, .jpeg" #fileInputApp
                     (change)="uploadIcon(fileInputApp.files, 'app_icon')"/>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h2 class="section-header">{{ 'form.teams.integrations.ticket_config.label' | translate }}</h2>

          <!-- Ticket Config Selection -->
          <mat-form-field appearance="fill" class="w-100 mb-3">
            <mat-label>{{ 'form.teams.integrations.ticket_config.label' | translate }}</mat-label>
            <mat-select formControlName="ticket_config_id" (selectionChange)="onTicketConfigChange($event)" required>
              <mat-option *ngFor="let config of ticketConfigs" [value]="config.id">
                {{ config.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="configForm.get('ticket_config_id').invalid">
              {{ 'form.teams.integrations.ticket_config.errors.required' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100 mb-3">
            <mat-label>{{ 'form.teams.integrations.types.select' | translate }}</mat-label>
            <mat-select multiple [disabled]="!selectedTicketConfig" (selectionChange)="onTypeSelectionChange($event)">
              <mat-option *ngFor="let type of selectedTicketConfig?.custom_fields?.type" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{ 'form.teams.integrations.custom_fields.select' | translate }}</mat-label>
            <mat-select multiple [disabled]="!selectedTicketConfig">
              <mat-option *ngFor="let field of getTicketConfigCustomFields(); let i = index"
                          [value]="field"
                          (click)="toggleCustomField(i, field)">
                {{ 'form.customFields.' + field + '.label' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Members Configuration -->
      <div class="form-section mt-2">
        <h2 class="section-header">{{ 'form.teams.integrations.members_config.label' | translate }}</h2>
        <div formGroupName="members">
          @for (typeEntry of getMembersControls() | keyvalue; track typeEntry.key) {
            <div [formGroupName]="typeEntry.key" class="member-type-container">
              <mat-toolbar class="type-header">
                <h3>{{ getDisplayTypeName(typeEntry.key) }}</h3>
              </mat-toolbar>

              @if (typeEntry.value.get('hasLevels').value) {
                <div formGroupName="levels" class="levels-container">
                  @for (levelEntry of getLevelsControls(typeEntry.key) | keyvalue; track levelEntry.key) {
                    <div class="level-container">
                      <div [formArrayName]="levelEntry.key">
                        <mat-toolbar class="level-toolbar">
                          <h4 class="level-title">
                            {{ 'form.customFields.level.label' | translate }} {{ getDisplayLevelName(levelEntry.key) | lowercase }}
                          </h4>
                        </mat-toolbar>
                        <div class="p-3">
                          @for (control of getParticipantsArray(typeEntry.key, levelEntry.key)?.controls; track i; let i = $index) {
                            <div class="participant-row">
                              <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{ 'form.teams.integrations.select_member.label' | translate }}</mat-label>
                                <mat-select [formControlName]="i">
                                  @for (teamMember of teamMembers; track teamMember.id) {
                                    <mat-option [value]="teamMember">
                                      {{ teamMember.member.first_name }} {{ teamMember.member.last_name }}
                                    </mat-option>
                                  }
                                </mat-select>
                              </mat-form-field>
                              <button type="button" mat-icon-button color="warn" class="delete-icon"
                                      (click)="removeMemberFromLevel(typeEntry.key, levelEntry.key, i)">
                                <mat-icon>delete</mat-icon>
                              </button>
                            </div>
                          }
                          <button type="button" mat-button class="add-button"
                                  (click)="addMemberToLevel(typeEntry.key, levelEntry.key)">
                            {{ 'teams.integrations.add_member' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              } @else {
                <div formArrayName="participants" class="participants-container">
                  @for (control of getParticipantsArray(typeEntry.key)?.controls; track i; let i = $index) {
                    <div class="participant-row">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{ 'form.teams.integrations.select_member.label' | translate }}</mat-label>
                        <mat-select [formControlName]="i">
                          @for (teamMember of teamMembers; track teamMember.id) {
                            <mat-option [value]="teamMember">
                              {{ teamMember.member.first_name }} {{ teamMember.member.last_name }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      <button type="button" mat-icon-button color="warn" class="delete-icon"
                              (click)="removeMemberFromType(typeEntry.key, i)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  }
                  <button type="button" mat-button class="add-button"
                          (click)="addMemberToType(typeEntry.key)">
                    {{ 'teams.integrations.add_member' | translate }}
                  </button>
                </div>
              }
            </div>
          }
        </div>
      </div>

      <!-- Description Fields Section -->
      <div class="form-section mt-2">
        <h2 class="section-header">{{ 'form.teams.integrations.description_fields.label' | translate }}</h2>
        <div formGroupName="description_fields" class="description-fields">
          <!-- Color Fields -->
          <div formGroupName="color" class="fields-subsection">
            <h3>{{ 'form.teams.integrations.color.label' | translate }}</h3>
            <div class="level-group">
              @for (typeEntry of getMembersControls() | keyvalue; track typeEntry.key) {
                <div class="level-fields">
                  <h4>{{ getDisplayTypeName(typeEntry.key) }}</h4>
                  @if (typeEntry.value.get('hasLevels').value) {
                    <div class="level-group-row">
                      @for (levelEntry of getLevelsControls(typeEntry.key) | keyvalue; track levelEntry.key) {
                        <div class="color-field">
                          <mat-form-field appearance="fill" [formGroupName]="typeEntry.key">
                            <mat-label>{{ getDisplayLevelName(levelEntry.key) }}</mat-label>
                            <input type="color" matInput [formControlName]="levelEntry.key">
                          </mat-form-field>
                        </div>
                      }
                    </div>
                  } @else {
                    <mat-form-field appearance="fill" class="color-field">
                      <mat-label>{{ 'form.teams.integrations.default_type_name.label' | translate }}</mat-label>
                      <input type="color" matInput [formControlName]="typeEntry.key">
                    </mat-form-field>
                  }
                </div>
              }
            </div>
          </div>

          <!-- Description Fields -->
          <div formGroupName="description" class="fields-subsection">
            <h3>{{ 'form.teams.integrations.description.label' | translate }}</h3>
            <div class="level-group">
              @for (typeEntry of getMembersControls() | keyvalue; track typeEntry.key) {
                <div class="level-fields">
                  <h4>{{ getDisplayTypeName(typeEntry.key) }}</h4>
                  @if (typeEntry.value.get('hasLevels').value) {
                    @for (levelEntry of getLevelsControls(typeEntry.key) | keyvalue; track levelEntry.key; ) {
                      <mat-form-field appearance="fill" class="w-100" [formGroupName]="typeEntry.key">
                        <mat-label>{{ getDisplayLevelName(levelEntry.key) }}</mat-label>
                        <input type="text" matInput [formControlName]="levelEntry.key">
                      </mat-form-field>
                    }
                  } @else {
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>{{ 'form.teams.integrations.default_type_name.label' | translate }}</mat-label>
                      <input type="text" matInput [formControlName]="typeEntry.key">
                    </mat-form-field>
                  }
                </div>
              }
            </div>
          </div>

          <!-- Label Fields -->
          <div formGroupName="label" class="fields-subsection">
            <h3>{{ 'form.teams.integrations.label.label' | translate }}</h3>
            <div class="level-group">
              @for (typeEntry of getMembersControls() | keyvalue; track typeEntry.key) {
                <div class="level-fields">
                  <h4>{{ getDisplayTypeName(typeEntry.key) }}</h4>
                  @if (typeEntry.value.get('hasLevels').value) {
                    @for (levelEntry of getLevelsControls(typeEntry.key) | keyvalue; track levelEntry.key) {
                      <mat-form-field appearance="fill" class="w-100" [formGroupName]="typeEntry.key">
                        <mat-label>{{ getDisplayLevelName(levelEntry.key) }}</mat-label>
                        <input type="text" matInput [formControlName]="levelEntry.key">
                      </mat-form-field>
                    }
                  } @else {
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>{{ 'form.teams.integrations.default_type_name.label' | translate }}</mat-label>
                      <input type="text" matInput [formControlName]="typeEntry.key">
                    </mat-form-field>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="button-container mt-2">
        <button mat-flat-button color="primary" type="submit" [disabled]="configForm.invalid || isSubmitting">
          {{ 'form.teams.integrations.save.label' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<!-- Progress bar template -->
<ng-template #progressBar>
  <div class="progress-container">
    <span>0%</span>
    <mat-progress-bar [mode]="upload?.state === 'PENDING' ? 'buffer' : 'determinate'" [value]="upload?.progress"
                      class="progress-bar"></mat-progress-bar>
    <span>100%</span>
  </div>
</ng-template>

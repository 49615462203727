import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import {
  ActionLog,
  ActionLogsService,
  User,
  UserRoles,
  UserService,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import { DataTableComponent } from '../data-table/data-table.component';

@Component({
  selector: 'app-action-logs',
  templateUrl: './action.logs.component.html',
  standalone: false,
})
export class ActionLogsComponent extends DataTableComponent implements OnInit {
  @Input() userId: number;

  datasource: AjaxDataSource<ActionLog>;
  displayedColumns: Array<string> = ['user', 'action', 'ip', 'data', 'created_at'];
  userViewDisplayedColumns: Array<string> = ['action', 'ip', 'data', 'created_at'];
  actions: Array<string>;
  filterStorage = '_filterAdminActionLogs';
  filterService = 'actionLogsService';
  defaultFilters = {
    user_only: true,
    user_id: 0,
    action: '',
  };
  isAdmin = true;

  constructor(
    private actionLogsService: ActionLogsService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.datasource = new AjaxDataSource<ActionLog>();

    if (this.userId) {
      this.filterStorage = '_filterUserActionLogs';
    }

    this.initFilters();
    this.actionLogsService.options().subscribe((options: { [name: string]: [] }) => {
      this.actions = options.actions;
    });
  }

  reload() {
    this.userService.currentUser.subscribe({
      next: (user: User) => {
        if (user.role === UserRoles.USER) {
          this.ajaxParams.filter.user_id = user.id;
          this.isAdmin = false;
          let { user_only, user_id, ...filters } = this.ajaxParams?.filter ?? this.defaultFilters;
          this.ajaxParams.filter = filters;
        } else {
          if (this.userId) {
            this.ajaxParams.filter.user_id = this.userId;
          }
        }
        this.updateDataSource(this.actionLogsService.fetchActionLogs(this.ajaxParams.getHttpParams()));
      },
    });
  }

  clearFilters() {
    this.resetFilters();
    this.applyFilter();
  }
}

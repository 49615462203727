import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import {
  asyncScheduler,
  Subject,
  Subscription,
} from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { UserService } from './user.service';

import { environment } from '../../../environment';
import { AppType } from '../models';

@Injectable({ providedIn: 'root' })
export class AppService {
  static appName: string = environment.project as string;
  static appType: string = environment.app as AppType;
  titleChange: Subject<string> = new Subject<string>();
  activePageChange: Subject<string> = new Subject<string>();
  sideBarExpandedChange: Subject<boolean> = new Subject<boolean>();
  isMobile = false;

  tokenRefreshSubject: Subject<void> = new Subject<void>();

  private changeLang$: Subscription = Subscription.EMPTY;

  private pActiveTab: string = null;
  private pSideBarExpanded = true;

  get title() {
    return this.titleService.getTitle();
  }

  set title(titleTranslate: string) {
    this.changeTitle(titleTranslate);
    if (!this.changeLang$.closed) {
      this.changeLang$.unsubscribe();
    }
    this.changeLang$ = this.translateService.onLangChange.subscribe({
      next: () => this.changeTitle(titleTranslate),
    });
  }

  get active() {
    return this.pActiveTab;
  }

  set active(page) {
    this.pActiveTab = page;
    this.activePageChange.next(page);
  }

  get expanded() {
    return this.pSideBarExpanded;
  }

  set expanded(expanded) {
    this.pSideBarExpanded = expanded;
    this.sideBarExpandedChange.next(expanded);
    localStorage.setItem('sideBarExpanded', JSON.stringify(expanded));
  }

  get adminSessionDuration() {
    return 15 * 60;
  }

  get tokenRefreshTimeout() {
    return Math.ceil(this.adminSessionDuration / 4);
  }

  constructor(
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    private readonly titleService: Title,
  ) {
    let refreshTime = new Date().getTime();

    this.tokenRefreshSubject.asObservable()
      .pipe(throttleTime(this.tokenRefreshTimeout * 1000, asyncScheduler, { leading: true, trailing: false }))
      .subscribe(() => {
        refreshTime = new Date().getTime();
        this.userService.refreshToken();
      });

    const checkIsMobile = () => {
      const isMobile = window.innerWidth <= 540;
      if (!this.isMobile && isMobile) {
        this.expanded = false;
      }
      this.isMobile = isMobile;
    };
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    if (this.isMobile) {
      this.expanded = false;
    } else if (localStorage.getItem('sideBarExpanded')) {
      this.expanded = JSON.parse(localStorage.getItem('sideBarExpanded'));
    }
  }

  private changeTitle(titleTranslate: string) {
    this.translateService.get(titleTranslate).subscribe((res: string) => {
      this.titleService.setTitle(`${res} | ${AppService.appName}`);
      this.titleChange.next(res);
    });
  }
}

<div class="px-12 justify-content-center flex-form">
  <form [formGroup]="createProject" (ngSubmit)="onSubmit()">
    <div class="flex-center-form flex-column">
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.projectName.label' | translate}}</mat-label>
        <input matInput formControlName="name">
        <mat-error>
          <app-validation-error-render label="projectName"
                                       [errors]="createProject.controls.name.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.projectKey.label' | translate}}</mat-label>
        <input matInput formControlName="key">
        <mat-error>
          <app-validation-error-render label="projectKey"
                                       [errors]="createProject.get('key').errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.teamId.label' | translate}}</mat-label>
        <mat-select formControlName="team_id" (selectionChange)="onTeamChange($event)">
          <mat-option *ngFor="let team of teams" [value]="team.id">{{ team.name }}</mat-option>
        </mat-select>
        <mat-error>
          <app-validation-error-render label="teamId"
                                       [errors]="createProject.controls.team_id.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.ticketConfig.label' | translate}}</mat-label>
        <mat-select formControlName="ticket_config_id" (selectionChange)="onTicketConfigChange($event)">
          <mat-option *ngFor="let ticketConfig of ticketConfigs" [value]="ticketConfig.id">{{ ticketConfig.name }}</mat-option>
        </mat-select>
        <mat-error>
          <app-validation-error-render label="ticketConfig"
                                       [errors]="createProject.controls.ticket_config_id.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.slackConfig.label' | translate}}</mat-label>
        <mat-select formControlName="slack_config_id" [disabled]="!selectedTicketConfigId">
          <mat-option *ngFor="let slackConfig of slackConfigs" [value]="slackConfig.id">{{ slackConfig.name }}</mat-option>
        </mat-select>
        <mat-error>
          <app-validation-error-render label="slackConfig"
                                       [errors]="createProject.controls.slack_config_id.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.trelloConfig.label' | translate}}</mat-label>
        <mat-select formControlName="trello_config_id" [disabled]="!selectedTicketConfigId">
          <mat-option *ngFor="let trelloConfig of trelloConfigs" [value]="trelloConfig.id">{{ trelloConfig.name }}</mat-option>
        </mat-select>
        <mat-error>
          <app-validation-error-render label="trelloConfig"
                                       [errors]="createProject.controls.trello_config_id.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" type="submit" [disabled]="!createProject.valid">
      {{ 'actions.submit' | translate }}
    </button>
  </form>
</div>

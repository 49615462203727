<div class="page-container">
  <div>
    <div class="spinner" *ngIf="loading">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
      </mat-progress-spinner>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="[trelloConfig]" *ngIf="trelloConfig" aria-label="slackConfig" matSort>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'teams.integrations.id' | translate }}</th>
          <td mat-cell *matCellDef="let trelloConfig">{{ trelloConfig.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'teams.integrations.name' | translate }}</th>
          <td mat-cell *matCellDef="let trelloConfig">{{ trelloConfig.name }}</td>
        </ng-container>

        <ng-container matColumnDef="custom_fields">
          <th mat-header-cell *matHeaderCellDef>{{ 'teams.integrations.custom_fields.title' | translate }}</th>
          <td mat-cell *matCellDef="let trelloConfig">
            <div *ngFor="let field of trelloConfig.custom_fields">
              {{ field }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>{{ 'actions.action' | translate }}</th>
          <td mat-cell *matCellDef="let trelloConfig" class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              <button mat-icon-button color="warn delete-icon" (click)="openDeleteDialog()">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

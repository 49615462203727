import { HttpParams } from '@angular/common/http';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  PaginationContent,
  Team,
  TeamMember,
  TeamMemberService,
  TeamsService,
  TicketConfig,
  TicketConfigsService,
  TicketDefaultKey,
  ToastsService,
} from '../../../core';

@Component({
  selector: 'app-integration-config-form',
  template: '',
})
export abstract class IntegrationConfigFormComponent implements OnInit, AfterContentChecked {
  protected readonly teamsService: TeamsService = inject(TeamsService);
  protected readonly appService: AppService = inject(AppService);
  protected readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly router: Router = inject(Router);
  protected readonly route: ActivatedRoute = inject(ActivatedRoute);
  protected readonly fb: FormBuilder = inject(FormBuilder);
  protected readonly toastsService: ToastsService = inject(ToastsService);
  protected readonly translate: TranslateService = inject(TranslateService);
  protected readonly teamMemberService: TeamMemberService = inject(TeamMemberService);
  protected readonly ticketConfigsService: TicketConfigsService = inject(TicketConfigsService);

  protected readonly descriptionFields: string[] = [];

  team: Team;
  teamId: number;
  teamMembers: Array<TeamMember>;

  configForm: FormGroup;

  isSubmitting: boolean = false;
  loading = true;

  ticketConfigs: TicketConfig[] = [];
  selectedTicketConfig: TicketConfig;
  selectedTypes: string[] = [];

  abstract addMemberType(typeName: string): void;

  get membersFormGroup(): FormGroup {
    return this.configForm.get('members') as FormGroup;
  }

  get customFields(): FormArray {
    return this.configForm.get('custom_fields') as FormArray;
  }

  getDisplayTypeName(typeName: string): string {
    if (typeName === TicketDefaultKey) {
      return this.translate.instant('form.teams.integrations.default_type_name.label');
    }
    return typeName;
  }

  getDisplayLevelName(levelName: string): string {
    if (levelName === TicketDefaultKey) {
      return this.translate.instant('form.teams.integrations.default_type_name.label');
    }

    return levelName;
  }

  ngOnInit() {
    this.teamId = +this.route.snapshot.paramMap.get('id');

    if (this.teamId) {
      this.fetchTeamMembers();
      this.fetchTicketConfigs();
      this.loadTeamDetails();
      this.configForm.patchValue({ team_id: this.teamId });
    }
  }

  getTicketConfigCustomFields(): string[] {
    if (this.selectedTicketConfig && this.selectedTicketConfig.custom_fields) {
      return Object.keys(this.selectedTicketConfig.custom_fields);
    }
    return [];
  }

  toggleCustomField(index: number, fieldName: string): void {
    const control = this.customFields.at(index);
    const currentValue = control.value;
    control.setValue({
      name: fieldName,
      selected: !currentValue.selected,
    });
  }

  protected fetchTicketConfigs(): void {
    this.ticketConfigsService.fetchTicketConfigs(new HttpParams().set('team_id', this.teamId))
      .subscribe({
        next: (response: PaginationContent<TicketConfig>): void => {
          this.ticketConfigs = response.list;
          this.configForm.get('ticket_config_id').enable();
        },
        error: (err): void => {
          this.toastsService.add(err);
        },
      });
  }

  protected fetchTeamMembers(): void {
    this.teamMemberService.fetchTeamMembers(this.teamId).subscribe(data => {
      this.teamMembers = data.list;
    });
  }

  protected loadTeamDetails(): void {
    this.teamsService.getTeam(this.teamId).subscribe({
      next: (team: Team) => {
        this.team = team;
        this.appService.title = `Integrations for ${this.team.name}`;
        this.loading = false;
      },
      error: () => this.router.navigateByUrl(`/teams/${this.teamId}`),
    });
  }

  protected getParticipantsArray(typeName: string, levelNumber?: string): FormArray | null {
    const typeGroup = this.configForm.get('members').get(typeName);
    if (typeGroup) {
      if (typeGroup.get('hasLevels').value && levelNumber) {
        const levelsGroup = typeGroup.get('levels') as FormGroup;
        if (levelsGroup && levelsGroup.contains(levelNumber)) {
          return levelsGroup.get(levelNumber) as FormArray;
        }
      } else {
        return typeGroup.get('participants') as FormArray;
      }
    }
    return null;
  }

  protected onTypeSelectionChange(event: any): void {
    this.selectedTypes = event.value;

    // Update the members form group based on selected types
    const membersGroup = this.membersFormGroup;

    // First make sure default exists
    if (!membersGroup.contains(TicketDefaultKey)) {
      this.addMemberType(TicketDefaultKey);
    }

    // Add all selected types
    this.selectedTypes.forEach(type => {
      if (!membersGroup.contains(type)) {
        this.addMemberType(type);

        // Check if this type requires levels
        const hasLevels = this.typeRequiresLevel(type);
        const typeGroup = this.getTypeGroup(type);

        if (hasLevels && typeGroup) {
          typeGroup.get('hasLevels').setValue(true);
          this.setupLevelsForType(type);
        }
      }
    });

    // Remove any unselected types (except default)
    Object.keys(membersGroup.controls).forEach(key => {
      if (key !== TicketDefaultKey && !this.selectedTypes.includes(key)) {
        this.removeMemberType(key);
      }
    });
  }

  protected typeRequiresLevel(type: string): boolean {
    if (this.selectedTicketConfig && this.selectedTicketConfig.required_fields) {
      const requiredFields = this.selectedTicketConfig.required_fields[type];
      return requiredFields && requiredFields.includes('level');
    }
    return false;
  }

  setupLevelsForType(typeName: string): void {
    const typeGroup = this.getTypeGroup(typeName);
    if (typeGroup) {
      const participantsArray = typeGroup.get('participants') as FormArray;
      const levelsGroup = this.fb.group({});
      typeGroup.setControl('levels', levelsGroup);

      // Add default level first
      levelsGroup.addControl(TicketDefaultKey, this.fb.array([]));

      // Add all possible levels from ticket config
      if (this.selectedTicketConfig && this.selectedTicketConfig.custom_fields &&
        this.selectedTicketConfig.custom_fields.level) {

        this.selectedTicketConfig.custom_fields.level.forEach(level => {
          const levelKey = level.toString();
          levelsGroup.addControl(levelKey, this.fb.array([]));

          // Set up description fields for this level
          const descriptionFields = this.configForm.get('description_fields') as FormGroup;
          this.descriptionFields.forEach(field => {
            const fieldGroup = descriptionFields.get(field) as FormGroup;
            const defaultValue = field === 'color' ? '#000fff' : '';

            if (!fieldGroup.contains(typeName)) {
              fieldGroup.addControl(typeName, this.fb.group({
                [TicketDefaultKey]: [defaultValue],
                [levelKey]: [defaultValue],
              }));
            } else if (fieldGroup.get(typeName) instanceof FormControl) {
              const currentValue = fieldGroup.get(typeName).value;
              fieldGroup.removeControl(typeName);
              fieldGroup.addControl(typeName, this.fb.group({
                [TicketDefaultKey]: [currentValue || defaultValue],
                [levelKey]: [defaultValue],
              }));
            } else {
              // It's already a group, just add the level
              const typeGroup = fieldGroup.get(typeName) as FormGroup;
              if (!typeGroup.contains(TicketDefaultKey)) {
                typeGroup.addControl(TicketDefaultKey, this.fb.control(defaultValue));
              }
              if (!typeGroup.contains(levelKey)) {
                typeGroup.addControl(levelKey, this.fb.control(defaultValue));
              }
            }
          });
        });

        // If we have participants, move them to default level
        if (participantsArray && participantsArray.length > 0) {
          const defaultLevelArray = levelsGroup.get(TicketDefaultKey) as FormArray;

          // Copy participants to default level
          while (participantsArray.length > 0) {
            const control = participantsArray.at(0);
            participantsArray.removeAt(0);
            defaultLevelArray.push(control);
          }
        }
      }

      typeGroup.get('groupingAdded').setValue(true);
    }
  }

  protected getTypeGroup(typeName: string): FormGroup {
    const membersGroup = this.membersFormGroup;
    if (membersGroup.contains(typeName)) {
      return membersGroup.get(typeName) as FormGroup;
    } else {
      return null;
    }
  }

  protected getLevelsControls(typeName: string): { [key: string]: AbstractControl } {
    const levelsGroup = this.getLevelsGroup(typeName);
    if (levelsGroup) {
      return levelsGroup.controls;
    }
    return {};
  }

  protected getLevelsGroup(typeName: string): FormGroup {
    const typeGroup = this.getTypeGroup(typeName);
    if (typeGroup && typeGroup.contains('levels')) {
      return typeGroup.get('levels') as FormGroup;
    } else {
      return null;
    }
  }

  protected removeMemberType(typeName: string): void {
    if (TicketDefaultKey === typeName) {
      this.toastsService.add(this.translate.instant('teams.integrations.failed_delete', { typeName: typeName }));
      return;
    }

    if (this.membersFormGroup.contains(typeName)) {
      this.membersFormGroup.removeControl(typeName);
      const descriptionFields = this.configForm.get('description_fields') as FormGroup;
      this.descriptionFields.forEach(field => {
        const fieldGroup = descriptionFields.get(field) as FormGroup;
        fieldGroup.removeControl(typeName);
      });
    }
  }

  protected addMemberToType(typeName: string): void {
    const participantsArray = this.getParticipantsArray(typeName);
    if (participantsArray) {
      participantsArray.push(this.fb.control('', Validators.required));
    } else {
      this.toastsService.add(this.translate.instant('teams.integrations.config_load'));
    }
  }

  protected removeMemberFromType(typeName: string, index: number): void {
    const participantsArray = this.getTypeGroup(typeName).get('participants') as FormArray;
    participantsArray.removeAt(index);
  }

  protected addMemberToLevel(typeName: string, levelNumber: string): void {
    const participantsArray = this.getParticipantsArray(typeName, levelNumber);
    if (participantsArray) {
      participantsArray.push(this.fb.control('', Validators.required));
    } else {
      this.toastsService.add(this.translate.instant('teams.integrations.config_load'));
    }
  }

  protected removeMemberFromLevel(typeName: string, levelNumber: string, index: number): void {
    const participantsArray = this.getParticipantsArray(typeName, levelNumber);
    if (participantsArray) {
      participantsArray.removeAt(index);
    }
  }

  protected getMembersControls(): { [key: string]: AbstractControl } {
    return (this.configForm.get('members') as FormGroup).controls;
  }

  protected onTicketConfigChange(event: MatSelectChange): void {
    const ticketConfigId: number | null = event.value;
    this.selectedTicketConfig = this.ticketConfigs.find(config => config.id === ticketConfigId);

    if (this.selectedTicketConfig) {
      this.configForm.patchValue({
        ticket_config_id: ticketConfigId,
      });

      // Reset selected types
      this.selectedTypes = [];

      // Clear existing member types
      const membersGroup = this.configForm.get('members') as FormGroup;
      Object.keys(membersGroup.controls).forEach(key => {
        if (key !== TicketDefaultKey) {
          membersGroup.removeControl(key);
        }
      });

      // Update custom fields based on selected ticket config
      this.updateCustomFieldsOptions();
    }
  }

  protected updateCustomFieldsOptions(): void {
    // Clear existing custom fields
    while (this.customFields.length > 0) {
      this.customFields.removeAt(0);
    }

    if (this.selectedTicketConfig && this.selectedTicketConfig.custom_fields) {
      // Add a FormControl for each custom field option with the field name as value
      Object.keys(this.selectedTicketConfig.custom_fields).forEach(field => {
        this.customFields.push(this.fb.control({ name: field, selected: false }));
      });
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
}

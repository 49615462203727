<div class="page-container">
  <div>
    <div class="spinner" *ngIf="datasource.loading$ | async">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
    <div class="row mb-3">
      <div class="col d-flex align-items-center justify-content-end gap-1 mt-md-3 mt-lg-0">
        <button class="action-button" mat-flat-button color="primary" type="button" (click)="navigateToTrelloCreate()">
          {{ 'actions.create' | translate }}
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="datasource" aria-label="slackConfig" matSort>
        <ng-container matColumnDef="id">
          <th class="w-25" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'teams.integrations.id' | translate }}
          </th>
          <td mat-cell *matCellDef="let trelloConfig"> {{ trelloConfig.id }} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th class="w-25" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'teams.integrations.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let trelloConfig"> {{ trelloConfig.name }} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th class="w-25" mat-header-cell *matHeaderCellDef>
            {{ 'actions.action' | translate }}
          </th>
          <td mat-cell *matCellDef="let trelloConfig">
            <div class="d-flex align-items-center justify-content-end">
              <button mat-icon-button color="primary" type="button" (click)="navigateToTrello(trelloConfig.id)">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator #pagination [pageSizeOptions]="pageSizeOptions" [length]="datasource.total$ | async"></mat-paginator>
  </div>
</div>

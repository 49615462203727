import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  ProjectsService,
  TicketConfigsService,
  TicketsService,
} from '../../../../core';

@Component({
  selector: 'app-ticket-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss',
  standalone: false,
})
export class TicketEditComponent implements OnInit {
  isLoading = false;
  editTicket: FormGroup;
  ticketId: string;
  required_fields: { [key: string]: string[] } = {};
  typeOptions = [];
  customFieldKeys: string[] = [];
  customFieldOptions: { [key: string]: string[] } = {};
  selectedType = '';
  selectedTicketConfigId?: number = null;
  private ticketData: any;

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private router: Router,
    private ticketsService: TicketsService,
    private ticketConfigService: TicketConfigsService,
    private projectsService: ProjectsService,
    private translateService: TranslateService,
  ) {

  }

  ngOnInit() {
    this.appService.title = 'tickets.edit.title';
    this.editTicket = this.fb.group({
      source: ['', [Validators.required]],
      title: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      content: ['', [Validators.required, Validators.minLength(20)]],
      custom_fields: this.fb.group({
        type: [''],
      }),
      page_url: ['', [
        Validators.required,
        Validators.pattern(/https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&=]*)/),
      ]],
    });
    this.route.paramMap.subscribe(params => {
      this.ticketId = params.get('id') || '';
      if (this.ticketId) {
        this.loadExistingTicket();
      }
    });
  }

  onTypeChange(event: any) {
    this.selectedType = event.value;
    this.updateCustomFields();
  }

  private updateCustomFields() {
    const customFieldsGroup = this.editTicket.get('custom_fields') as FormGroup;
    const requiredFieldsForType = this.required_fields?.[this.selectedType] || [];

    const currentFields = Object.keys(customFieldsGroup.controls).filter(key => key !== 'type');

    currentFields.forEach(key => {
      if (!requiredFieldsForType.includes(key)) {
        customFieldsGroup.removeControl(key);
      }
    });

    requiredFieldsForType.forEach(field => {
      if (!customFieldsGroup.contains(field)) {
        customFieldsGroup.addControl(field, this.fb.control('', Validators.required));
      }
    });

    customFieldsGroup.get('type')?.setValue(this.selectedType);

    if (this.ticketData?.custom_fields) {
      const filteredCustomFields: { [key: string]: any } = { type: this.selectedType };
      requiredFieldsForType.forEach(field => {
        if (this.ticketData.custom_fields[field]) {
          filteredCustomFields[field] = this.ticketData.custom_fields[field];
        }
      });
      customFieldsGroup.patchValue(filteredCustomFields);
    }
  }

  loadExistingTicket() {
    this.ticketsService.getTicket(this.ticketId).subscribe({
      next: (ticket) => {
        this.ticketData = ticket;
        this.selectedType = ticket.custom_fields?.type || '';
        this.editTicket.patchValue({
          source: ticket.source,
          title: ticket.title,
          content: ticket.content,
          page_url: ticket.page_url,
        });
        if (ticket.source) {
          this.loadProjectAndConfigs(ticket.source);
        }
      },
    });
  }

  loadProjectAndConfigs(projectId: string) {
    if (projectId) {
      this.projectsService.getProject(projectId).subscribe({
        next: (project) => {
          this.selectedTicketConfigId = project.ticket_config_id;
          this.loadTicketConfigs();
        },
      });
    }
  }

  loadTicketConfigs() {
    this.ticketConfigService.getTicketConfig(this.selectedTicketConfigId).subscribe({
      next: (config) => {
        if (config && config.custom_fields) {
          this.typeOptions = config.custom_fields.type;
          this.customFieldKeys = Object.keys(config.custom_fields);
          this.customFieldOptions = this.convertOptionsToString(config.custom_fields);
          this.required_fields = config.required_fields;

          const customFieldsGroup = this.fb.group({
            type: [''],
          });
          this.editTicket.setControl('custom_fields', customFieldsGroup);

          if (this.selectedType) {
            this.onTypeChange({ value: this.selectedType });
          }
        }
      },
    });
  }

  getFilteredFields() {
    return this.required_fields?.[this.selectedType] || [];
  }

  convertOptionsToString(customFields: { [key: string]: (string[] | number[]) }): { [key: string]: string[] } {
    const result: { [key: string]: string[] } = {};
    for (const key in customFields) {
      if (Array.isArray(customFields[key])) {
        result[key] = customFields[key].map(option => option.toString());
      }
    }
    return result;
  }

  getTranslation(field: string): string {
    const key = `tickets.form.custom_fields.${field}.label`;
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : field;
  }

  onSubmit(): void {
    if (!this.editTicket.valid) {
      return;
    }

    this.isLoading = true;

    const formValue = this.editTicket.value;
    let ticketUpdateParams: any = {
      source: formValue.source,
      title: formValue.title,
      content: formValue.content,
      page_url: formValue.page_url,
      custom_fields: {
        type: this.selectedType,
      },
    };

    const requiredFieldsForType = this.required_fields[this.selectedType] || [];
    const customFields = formValue.custom_fields || {};

    requiredFieldsForType.forEach(field => {
      if (customFields[field]) {
        ticketUpdateParams.custom_fields[field] = customFields[field];
      }
    });

    if (this.ticketData?.custom_fields) {
      Object.keys(this.ticketData.custom_fields).forEach(field => {
        if (field !== 'type' && !requiredFieldsForType.includes(field)) {
          ticketUpdateParams.custom_fields[field] = null;
        }
      });
    }

    this.ticketsService.updateTicket(parseInt(this.ticketId), ticketUpdateParams).subscribe({
      next: ticket => {
        this.router.navigateByUrl('/tickets/' + ticket.id);
      },
      error: (err) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}

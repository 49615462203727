<div class="px-0 px-lg-5 justify-content-center">
  @if (isLoading) {
    <div>
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  }
  @if (!isLoading) {
    <form [formGroup]="createForm">
      <fieldset [disabled]="isSubmitting">
        <div class="row justify-content-center">
          <div [ngClass]="'col-12 col-lg-7 col-xl-6 col-md-9'">
            <div class="profile-card equal-height">
              <div class="profile-card__header">
                <div class="profile-card__header__title">
                  {{ 'actions.userProfile.generalTitle' | translate }}
                </div>
              </div>
              <div class="profile-card__body">
                <app-input formControlName="first_name"
                           [config]="{ id: 'first_name', label: 'firstName', class: 'w-100' }"
                           [errors]="createForm.get('first_name').errors"
                           [required]="true"
                ></app-input>
                <app-input formControlName="last_name"
                           [config]="{ id: 'last_name', label: 'lastName', class: 'w-100' }"
                           [errors]="createForm.get('last_name').errors"
                           [required]="true"
                ></app-input>
                <app-input formControlName="email"
                           [config]="{ id: 'email', label: 'email', type: 'email', class: 'w-100' }"
                           [errors]="createForm.get('email').errors"
                           [required]="true"
                ></app-input>
                @if (passwordField) {
                  <div class="row">
                    <app-input
                      formControlName="password"
                      [config]="{ id: 'password', label: 'password', type: hidePassword ? 'password' : 'text', class: 'w-100' }"
                      [errors]="createForm.get('password').errors"
                      [buttonConfig]="{icon: passIcon}"
                      [required]="true"
                      (clickButton)="hidePassword = !hidePassword">
                    </app-input>
                    <span class="input-hint px-4">{{ 'actions.passwordHint' | translate }}</span>
                  </div>
                }
                <div class="d-flex justify-content-end pt-2">
                  <button mat-flat-button color="primary" type="submit" class="submit ms-2" (click)="submitForm(true)"
                          [disabled]="isSubmitting || !createForm.valid || !createForm.touched">
                    {{ 'actions.saveAndInvite' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  }
</div>

<header class="header">
  <div class="header__logo">Hermes</div>
  <nav class="header__nav">
    <ul class="header__nav-list">
      <li class="header__nav-item"><a href="#how-it-works" class="header__nav-link">Features</a></li>
      <li class="header__nav-item"><a href="#testimonials" class="header__nav-link">Testimonials</a></li>
      <li class="header__nav-item">
        <button class="header__nav-btn" (click)="navigateToLogin()">Try now</button>
      </li>
    </ul>
  </nav>
</header>

<section class="hero">
  <div class="hero__content">
    <h1 class="hero__title">Simplify Bug Tracking & Feature Requests</h1>
    <p class="hero__description">
      A seamless and powerful solution for teams to track issues, request features, and collaborate efficiently.
    </p>
    <button class="hero__cta" (click)="navigateToLogin()">Try for Free</button>
  </div>
</section>

<section id="features" class="features">
  <h2 class="features__title">{{ features.title }}</h2>
  <div class="features__grid">
    <app-card *ngFor="let feature of features.data"
              [title]="feature.title"
              [description]="feature.description"
              [image]="feature.icon">
    </app-card>
  </div>
</section>

<section id="how-it-works" class="how-it-works">
  <h2 class="how-it-works__title">{{ howItWorks.title }}</h2>
  <div *ngFor="let step of howItWorks.data"
       [ngClass]="{'how-it-works__step--reverse': step.reverse}"
       class="how-it-works__step">
    <div class="how-it-works__text">
      <h3 class="how-it-works__step-title">{{ step.title }}</h3>
      <p class="how-it-works__step-description">{{ step.description }}</p>
    </div>
    <img [src]="step.imgSrc" [alt]="step.imgAlt" class="how-it-works__image">
  </div>
</section>

<section id="testimonials" class="testimonials">
  <h2 class="testimonials__title">{{ testimonials.title }}</h2>
  <div class="testimonials__grid">
    <app-card *ngFor="let testimonial of testimonials.data"
              [isReverse]="true"
              [isH3]="false"
              [title]="'- ' + testimonial.author"
              [description]=" testimonial.quote">
    </app-card>
  </div>
</section>

<section id="integration" class="integration">
  <h2 class="integration__title">{{ integrations.title }}</h2>
  <p class="integration__description">
    {{ integrations.description }}
  </p>
  <div class="integration__grid">
    <app-card *ngFor="let integration of integrations.data"
              [title]="integration.title"
              [description]="integration.description"
              [image]="integration.icon">
    </app-card>
  </div>
</section>

<section class="cta-section">
  <h2 class="cta-section__title">Start Tracking Bugs & Features Effortlessly</h2>
  <p class="cta-section__description">
    Get started today and experience a hassle-free way to manage issues and collect feedback.
  </p>
  <button class="cta-section__cta" (click)="navigateToLogin()">Get Started Now</button>
</section>

import {
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  Ticket,
  TicketsService,
  ToastsService,
  User,
  UserRoles,
  UserService,
} from '../../../../core';

@Component({
  selector: 'app-ticket-view',
  templateUrl: './view.component.html',
  styleUrl: './view.component.scss',
  standalone: false,
})
export class TicketViewComponent implements OnInit {
  protected readonly Number = Number;
  ticketId: number | undefined;
  ticket: Ticket;
  user: User;
  loading = false;
  displayedColumns: string[] = ['title', 'content', 'created_at'];

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private toastsService: ToastsService,
    private ticketsService: TicketsService,
    private userService: UserService,
  ) {
    this.appService.title = 'pages.tickets.show';
    this.appService.active = 'tickets';
  }

  ngOnInit(): void {
    this.ticketId = parseInt(this.route.snapshot.paramMap.get('id'));
    if (this.ticketId) {
      if (this.ticketId) {
        this.loading = true;
        this.ticketsService.getTicket(this.ticketId)
          .subscribe({
            next: (data) => {
              this.ticket = data;
              if (typeof this.ticket.custom_fields === 'string') {
                try {
                  this.ticket.custom_fields = JSON.parse(this.ticket.custom_fields);
                } catch (e) {
                  this.ticket.custom_fields = {};
                }
              }
              this.appService.title = this.translateService.instant('pages.tickets.show', {
                title: this.ticket.title,
              });
            },
            error: (err: string): void => {
              this.toastsService.add(err);
            },
            complete: () => {
              this.loading = false;
            },
          });
      }
    }
    this.userService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  isEditAllowed(ticket: Ticket): boolean {
    if (this.user.role === UserRoles.ADMIN || ticket.created_by === this.user.id) {
      if (!this.displayedColumns.includes('actions')) {
        this.displayedColumns.push('actions');
      }

      return true;
    }

    return false;
  }
}

import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
} from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import {
  MatCard,
  MatCardContent,
  MatCardFooter,
  MatCardTitle,
} from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import {
  MatIconModule,
  MatIconRegistry,
} from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import {
  ActionLogsComponent,
  AvatarComponent,
  CardComponent,
  CommentCreateComponent,
  CommentsComponent,
  ConfirmDialogComponent,
  CustomPaginationComponent,
  HeaderComponent,
  InputComponent,
  LocaleSwitcherComponent,
  NotFoundComponent,
  NotificationComponent,
  PageHeaderComponent,
  PasswordChangeDialogComponent,
  ProjectMembersComponent,
  ProjectSelectionDialogComponent,
  SidebarComponent,
  SocialButtonsComponent,
  TeamFilterComponent,
  TeamInviteComponent,
  ThemeToggleComponent,
  UserProfileComponent,
  ValidationErrorRenderComponent,
} from './components';
import { ErrorHandlerInterceptor } from './errors/error-handler.interceptor';
import {
  AuthGuard,
  NoAuthGuard,
  OnboardingGuard,
  PortalGuard,
  ProjectExistsGuard,
  TicketExistsGuard,
} from './guards';
import { JwtInterceptor } from './interceptors';
import { LazyTranslateLoader } from './loaders';
import {
  ActionLogsComponent as ActionLogsPage,
  LandingComponent as LandingPage,
  OnboardingComponent,
  ProjectCreateComponent as ProjectCreatePage,
  ProjectEditComponent as ProjectEditPage,
  ProjectsComponent as ProjectsPage,
  ProjectViewComponent as ProjectViewPage,
  SlackConfigCreateComponent as SlackConfigCreatePage,
  SlackConfigComponent as SlackConfigPage,
  TeamCreateComponent as TeamCreatePage,
  TeamsComponent as TeamsPage,
  TeamViewComponent as TeamViewPage,
  TicketConfigCreateComponent as TicketConfigCreatePage,
  TicketConfigComponent as TicketConfigPage,
  TicketConfigViewComponent as TicketConfigViewPage,
  TicketCreateComponent as TicketCreatePage,
  TicketEditComponent as TicketEditPage,
  TicketsComponent as TicketsPage,
  TicketViewComponent as TicketViewPage,
  TrelloConfigCreateComponent as TrelloConfigCreatePage,
  TrelloConfigComponent as TrelloConfigPage,
  TrelloConfigViewComponent as TrelloConfigViewPage,
} from './pages';
import {
  LocalizedDatePipe,
  TruncatePipe,
} from './pipes';
import { LayoutService } from './services';

const APP_GUARDS = [
  AuthGuard,
  NoAuthGuard,
  OnboardingGuard,
  PortalGuard,
  ProjectExistsGuard,
  TicketExistsGuard,
];

const APP_PIPES = [
  LocalizedDatePipe,
  TruncatePipe,
];

const APP_LOADERS = [
  LazyTranslateLoader,
];

const APP_COMPONENTS = [
  AvatarComponent,
  UserProfileComponent,
  HeaderComponent,
  SidebarComponent,
  NotFoundComponent,
  NotificationComponent,
  PageHeaderComponent,
  LocaleSwitcherComponent,
  InputComponent,
  TeamInviteComponent,
  TrelloConfigPage,
  TrelloConfigCreatePage,
  TrelloConfigViewPage,
  SlackConfigPage,
  SlackConfigCreatePage,
  OnboardingComponent,
  SocialButtonsComponent,
  ThemeToggleComponent,
  ActionLogsComponent,
  ActionLogsPage,
  ConfirmDialogComponent,
  PasswordChangeDialogComponent,
  ProjectEditPage,
  ProjectsPage,
  ProjectViewPage,
  LandingPage,
  ProjectCreatePage,
  ValidationErrorRenderComponent,
  TeamsPage,
  TeamViewPage,
  TeamCreatePage,
  TicketConfigPage,
  TicketConfigCreatePage,
  TicketConfigViewPage,
  TicketsPage,
  TicketViewPage,
  TicketEditPage,
  TicketCreatePage,
  CommentsComponent,
  CardComponent,
  CommentCreateComponent,
  ProjectMembersComponent,
  TeamFilterComponent,
  ProjectSelectionDialogComponent,
];

const MATERIAL_COMPONENTS = [
  MatCheckboxModule,
  MatTableModule,
  MatBadgeModule,
  MatButtonModule,
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatGridListModule,
  MatListModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatMenuModule,
];

const APP_SERVICES = [
  LayoutService,
];

const CUSTOM_ICONS = [
  'github',
  'google',
  'facebook',
  'trello',
  'slack',
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MatDialogModule,
    ...MATERIAL_COMPONENTS,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCard,
    MatCardTitle,
    MatCardContent,
    MatCardFooter,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    NgxMatSelectSearchModule,
  ],
  declarations: [
    ...APP_COMPONENTS,
    ...APP_PIPES,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...APP_COMPONENTS,
    ...MATERIAL_COMPONENTS,
    ...APP_PIPES,
  ],
})

export class SharedModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.addIcons();
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
        { provide: MatPaginatorIntl, useClass: CustomPaginationComponent },
        ...APP_GUARDS,
        ...APP_LOADERS,
        ...APP_COMPONENTS,
        ...MATERIAL_COMPONENTS,
        ...APP_PIPES,
        ...APP_SERVICES,
      ],
    };
  }

  addIcons() {
    for (const icon of CUSTOM_ICONS) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${icon}.svg`)
      );
    }
  }
}

import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  TeamMember,
  TicketDefaultKey,
  TrelloConfigsService,
  TrelloCoverColors,
} from '../../../../../../core';
import { AjaxDataSource } from '../../../../../../core/services/ajax.datasource';
import { IntegrationConfigFormComponent } from '../../../../../components';

@Component({
  selector: 'app-integrations',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  standalone: false,
})
export class TrelloConfigCreateComponent extends IntegrationConfigFormComponent implements OnInit {
  @ViewChild('progressBar') progressBar: TemplateRef<any>;

  protected readonly TrelloCoverColors: string[] = TrelloCoverColors;

  override descriptionFields = ['cover'];

  datasource: AjaxDataSource<TeamMember>;
  upload: { state: string; progress: number };

  constructor(private trelloConfigService: TrelloConfigsService) {
    super();

    this.initializeForm();
    this.appService.title = 'pages.teams.integrations';
    this.appService.active = 'teams';
    this.datasource = new AjaxDataSource<TeamMember>();
  }

  initializeForm(): void {
    this.configForm = this.fb.group({
      name: ['', Validators.required],
      key: ['', Validators.required],
      token: ['', Validators.required],
      board_name: ['', Validators.required],
      ticket_config_id: [{ value: null, disabled: true }, Validators.required],
      team_id: [null, Validators.required],
      members: this.fb.group({}),
      lists: this.fb.array([
        this.fb.group({
          listName: ['', Validators.required],
          listValue: ['', Validators.required],
        }),
      ]),
      custom_fields: this.fb.array([]),
      trigger_lists: [[], Validators.required],
      is_private_discussion: [false, Validators.required],
      description_fields: this.fb.group({
        cover: this.fb.group({}),
      }),
    });

    this.addMemberType(TicketDefaultKey);
    this.addMemberToType(TicketDefaultKey);
  }

  get trelloLists(): FormArray {
    return this.configForm.get('lists') as FormArray;
  }

  addTrelloListValidator(): void {
    this.trelloLists.push(this.fb.group({
      listName: ['', Validators.required],
      listValue: ['', Validators.required],
    }));
  }

  removeTrelloListValidator(index: number): void {
    this.trelloLists.removeAt(index);
  }

  addMemberType(typeName: string): void {
    const membersGroup = this.configForm.get('members') as FormGroup;
    if (!membersGroup.contains(typeName)) {
      const typeGroup = this.fb.group({
        hasLevels: [false],
        participants: this.fb.array([]),
        levels: this.fb.group({}),
        groupingAdded: [false],
      });
      membersGroup.addControl(typeName, typeGroup);
      const coverGroup = this.configForm.get('description_fields.cover') as FormGroup;
      if (!coverGroup.contains(typeName)) {
        coverGroup.addControl(typeName, this.fb.control(''));
      }
    } else {
      this.toastsService.add(this.translate.instant('teams.integrations.config_load', { typeName: typeName }));
    }
  }

  onSubmit(form: FormGroup): void {
    if (!form.valid) {
      this.toastsService.add(this.translate.instant('teams.integrations.failed_form'));
    }

    this.isSubmitting = true;
    this.trelloConfigService.createTrelloConfig(this.prepareParams(form.value)).subscribe({
      next: (trello) => {
        this.isSubmitting = false;
        this.router.navigate([`/teams/${this.teamId}/integrations/trello/${trello.id}`]);
      },
      error: () => {
        this.isSubmitting = false;
        this.toastsService.add(this.translate.instant('teams.integrations.failed_save'));
      },
    });
  }

  prepareParams(formValue: any): any {
    const preparedValue = { ...formValue };
    const members = preparedValue.members;
    const preparedMembers = {};

    for (const typeName in members) {
      const typeGroup = members[typeName];

      if (typeGroup.hasLevels) {
        const levelsGroup = typeGroup.levels;
        preparedMembers[typeName] = {};

        for (const levelNumber in levelsGroup) {
          const participantsArray = levelsGroup[levelNumber];
          preparedMembers[typeName][levelNumber] = participantsArray
            .map((member: any) => this.getMemberName(member))
            .join(', ');
        }
      } else {
        const participantsArray = typeGroup.participants;
        preparedMembers[typeName] = participantsArray.map((member: any) => this.getMemberName(member));
      }
    }
    if (Array.isArray(preparedValue.lists)) {
      const listsObject = {};
      preparedValue.lists.forEach((listItem: any) => {
        if (listItem.listName && listItem.listValue) {
          listsObject[listItem.listName] = listItem.listValue;
        }
      });
      preparedValue.lists = listsObject;
    }

    if (preparedValue.description_fields && preparedValue.description_fields.cover) {
      const cover = preparedValue.description_fields.cover;
      const filteredCover = {};

      for (const typeName in cover) {
        if (typeof cover[typeName] === 'object') {
          const levels = cover[typeName];
          const filteredLevels = {};
          for (const levelNumber in levels) {
            if (levels[levelNumber] && levels[levelNumber].trim() !== '') {
              filteredLevels[levelNumber] = levels[levelNumber];
            }
          }
          if (Object.keys(filteredLevels).length) {
            filteredCover[typeName] = filteredLevels;
          }
        } else {
          if (cover[typeName] && cover[typeName].trim() !== '') {
            filteredCover[typeName] = cover[typeName];
          }
        }
      }

      if (Object.keys(filteredCover).length) {
        preparedValue.description_fields.cover = filteredCover;
      } else {
        delete preparedValue.description_fields;
      }
    } else {
      delete preparedValue.description_fields;
    }

    if (preparedValue.custom_fields) {
      preparedValue.custom_fields = preparedValue.custom_fields
        .filter((field: { selected: boolean, name: string }): boolean => field.selected)
        .map((field: { selected: boolean, name: string }): string => field.name);
    }

    const listValues = Object.values(preparedValue.lists);
    preparedValue.trigger_lists = preparedValue.trigger_lists
      .filter((field: string): boolean => listValues.includes(field));

    preparedValue.members = preparedMembers;
    return preparedValue;
  }

  getMemberName(member: any): string {
    if (member && member.member) {
      const firstName = member.member.first_name.replace(/\s+/g, '');
      const lastName = member.member.last_name.replace(/\s+/g, '');
      const fullName = `${firstName}${lastName}`;
      return `@${fullName}`;
    }
    return '';
  }

  getListsValues(): string[] {
    return this.configForm.get('lists').value
      .filter((item: { listName: string, listValue: string }): boolean => !!item.listValue)
      .map((item: { listName: string, listValue: string }): string => {
        return item.listValue;
      });
  }
}

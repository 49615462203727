<div *ngIf="loading; else content">
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<ng-template #customFieldDialog let-dialogRef="dialogRef">
  <h2 mat-dialog-title>{{ 'teams.integrations.custom_fields.label' | translate }}</h2>
  <mat-dialog-content>
    <mat-form-field appearance="fill" class="list-item">
      <mat-label>{{ 'teams.integrations.custom_fields.label' | translate }}</mat-label>
      <input matInput [(ngModel)]="newFieldName" [placeholder]="'teams.integrations.custom_fields.placeholder' | translate">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="primary" (click)="dialogRef.close(newFieldName)" [disabled]="!newFieldName">
      {{ 'Add' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
<ng-template #content>
  <div class="d-flex justify-content-around flex-row">
    <div class="d-flex align-items-center flex-column max-w-60">
      <h1>{{ 'teams.integrations.ticket_config' | translate }}</h1>
      <form [formGroup]="ticketConfigForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="list-item">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input matInput formControlName="name" required>
          <mat-error *ngIf="ticketConfigForm.get('name').invalid">{{ 'Field is required' | translate }}</mat-error>
        </mat-form-field>
        <div formGroupName="custom_fields">
          <div class="d-flex align-items-center">
            <h3>{{ 'teams.integrations.custom_fields.title' | translate }}</h3>
            <button class="mx-3" mat-icon-button color="primary" (click)="openCustomFieldModal()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <div class="row">
            <div *ngFor="let field of getKeys()" class="col-md-6">
              <div [formArrayName]="field" class="block-margin">
                <h4>{{ getTranslation(field) }}</h4>
                <div *ngFor="let control of getFieldArray(field).controls; let idx = index" class="field-row">
                  <mat-form-field *ngIf="field === 'type'" class="list-item">
                    <input
                      matInput
                      [value]="control.value"
                      (input)="onTypeChange(idx, $event)"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field *ngIf="field !== 'type'" class="list-item">
                    <input matInput [formControlName]="idx" required>
                  </mat-form-field>
                  <button *ngIf="getFieldArray(field).controls.length > 1" mat-icon-button color="warn" (click)="removeField(field, idx)" class="remove-button">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <button mat-flat-button color="primary" (click)="addField(field)">{{ 'teams.integrations.add' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
        <div formGroupName="required_fields">
          <h3>{{ 'teams.integrations.required_fields' | translate }}</h3>
          <div *ngFor="let type of customFields.get('type').value">
            <h4>{{ type }}</h4>
            <mat-form-field appearance="fill" class="list-item">
              <mat-label>{{ 'Select Required Fields' | translate }}</mat-label>
              <mat-select [value]="getTypeRequiredFields(type).value"
                          (selectionChange)="onRequiredFieldsChange(type, $event.value)"
                          multiple>
                <mat-option *ngFor="let field of getCustomFieldKeys()" [value]="field">
                  {{ field | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field appearance="fill" class="list-item">
          <mat-label>{{ 'Done Status' | translate }}</mat-label>
          <input matInput formControlName="done_status" required>
          <mat-error *ngIf="ticketConfigForm.get('done_status').invalid">{{ 'Field is required' | translate }}</mat-error>
        </mat-form-field>
        <button mat-flat-button color="primary" type="submit" [disabled]="ticketConfigForm.invalid">
          {{ 'Save' | translate }}
        </button>
      </form>
    </div>
  </div>
</ng-template>

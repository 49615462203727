<div *ngIf="loading; else content">
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</div>

<ng-template #content>
  <div class="form-container">
    <h1 class="mb-3">{{ 'teams.integrations.trello_config' | translate }}</h1>
    <form [formGroup]="configForm" (ngSubmit)="onSubmit(configForm)">
      <div class="form-grid">
        <!-- Basic Information Section -->
        <div class="form-section">
          <h2 class="section-header">{{ 'teams.integrations.basic_info' | translate }}</h2>

          <!-- Config Name -->
          <app-input
            formControlName="name" [config]="{ id: 'name', label: 'teams.integrations.name', class: 'w-100' }"
            [errors]="configForm.get('name').errors" [required]="true"></app-input>

          <!-- API key -->
          <app-input
            formControlName="key" [config]="{ id: 'key', label: 'teams.integrations.api_key', class: 'w-100' }"
            [errors]="configForm.get('key').errors" [required]="true"></app-input>

          <!-- API key -->
          <app-input
            formControlName="token"
            [config]="{ id: 'token', label: 'teams.integrations.token', class: 'w-100' }"
            [errors]="configForm.get('token').errors" [required]="true"></app-input>

          <!-- Board name -->
          <app-input
            formControlName="board_name"
            [config]="{ id: 'board_name', label: 'teams.integrations.board_name', class: 'w-100' }"
            [errors]="configForm.get('board_name').errors" [required]="true"></app-input>

          <!-- Private Discussion Toggle -->
          <mat-checkbox formControlName="is_private_discussion">
            {{ 'teams.integrations.set_private_discussion' | translate }}
          </mat-checkbox>
        </div>

        <div class="form-section">
          <h2 class="section-header">{{ 'form.teams.integrations.ticket_config.label' | translate }}</h2>

          <!-- Ticket Config Selection -->
          <mat-form-field appearance="fill" class="w-100 mb-3">
            <mat-label>{{ 'form.teams.integrations.ticket_config.label' | translate }}</mat-label>
            <mat-select formControlName="ticket_config_id" (selectionChange)="onTicketConfigChange($event)" required>
              @for (config of ticketConfigs; track config.id) {
                <mat-option [value]="config.id">{{ config.name }}</mat-option>
              }
            </mat-select>
            @if (configForm.get('ticket_config_id').invalid) {
              <mat-error>{{ 'form.teams.integrations.ticket_config.errors.required' | translate }}</mat-error>
            }
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100 mb-3">
            <mat-label>{{ 'form.teams.integrations.types.select' | translate }}</mat-label>
            <mat-select multiple [disabled]="!selectedTicketConfig" (selectionChange)="onTypeSelectionChange($event)">
              <mat-option *ngFor="let type of selectedTicketConfig?.custom_fields?.type" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{ 'form.teams.integrations.custom_fields.select' | translate }}</mat-label>
            <mat-select multiple [disabled]="!selectedTicketConfig">
              <mat-option *ngFor="let field of getTicketConfigCustomFields(); let i = index"
                          [value]="field"
                          (click)="toggleCustomField(i, field)">
                {{ 'form.customFields.' + field + '.label' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Members Configuration -->
      <div class="form-section mt-2">
        <h2 class="section-header">{{ 'form.teams.integrations.members_config.label' | translate }}</h2>
        <div formGroupName="members">
          @for (typeEntry of getMembersControls() | keyvalue; track typeEntry.key) {
            <div [formGroupName]="typeEntry.key" class="member-type-container">
              <mat-toolbar class="type-header">
                <h3>{{ getDisplayTypeName(typeEntry.key) }}</h3>
              </mat-toolbar>

              @if (typeEntry.value.get('hasLevels').value) {
                <div formGroupName="levels" class="levels-container">
                  @for (levelEntry of getLevelsControls(typeEntry.key) | keyvalue; track levelEntry.key) {
                    <div class="level-container">
                      <div [formArrayName]="levelEntry.key">
                        <mat-toolbar class="level-toolbar">
                          <h4 class="level-title">
                            {{ 'form.customFields.level.label' | translate }} {{
                              getDisplayLevelName(levelEntry.key) |
                                lowercase
                            }}
                          </h4>
                        </mat-toolbar>
                        <div class="p-3">
                          @for (control of getParticipantsArray(typeEntry.key, levelEntry.key)?.controls; track i; let i = $index) {
                            <div class="participant-row">
                              <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{ 'form.teams.integrations.select_member.label' | translate }}</mat-label>
                                <mat-select [formControlName]="i">
                                  @for (teamMember of teamMembers; track teamMember.id) {
                                    <mat-option [value]="teamMember">
                                      {{ teamMember.member.first_name }} {{ teamMember.member.last_name }}
                                    </mat-option>
                                  }
                                </mat-select>
                              </mat-form-field>
                              <button type="button" mat-icon-button color="warn" class="delete-icon"
                                      (click)="removeMemberFromLevel(typeEntry.key, levelEntry.key, i)">
                                <mat-icon>delete</mat-icon>
                              </button>
                            </div>
                          }
                          <button type="button" mat-button class="add-button"
                                  (click)="addMemberToLevel(typeEntry.key, levelEntry.key)">
                            {{ 'teams.integrations.add_member' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              } @else {
                <div formArrayName="participants" class="participants-container">
                  @for (control of getParticipantsArray(typeEntry.key)?.controls; track i; let i = $index) {
                    <div class="participant-row">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{ 'form.teams.integrations.select_member.label' | translate }}</mat-label>
                        <mat-select [formControlName]="i">
                          @for (teamMember of teamMembers; track teamMember.id) {
                            <mat-option [value]="teamMember">
                              {{ teamMember.member.first_name }} {{ teamMember.member.last_name }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      <button type="button" mat-icon-button color="warn" class="delete-icon"
                              (click)="removeMemberFromType(typeEntry.key, i)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  }
                  <button type="button" mat-button class="add-button" (click)="addMemberToType(typeEntry.key)">
                    {{ 'teams.integrations.add_member' | translate }}
                  </button>
                </div>
              }
            </div>
          }
        </div>
      </div>

      <div class="form-section mt-2">
        <h2 class="section-header">{{ 'form.teams.integrations.description_fields.label' | translate }}</h2>
        <div formGroupName="description_fields" class="description-fields">
          <div formGroupName="cover" class="fields-subsection">
            <h3>{{ 'form.teams.integrations.cover.label' | translate }}</h3>
            @for (typeEntry of getMembersControls() | keyvalue; track typeEntry.key) {
              <div class="level-fields">
                <h4>{{ getDisplayTypeName(typeEntry.key) }}</h4>
                @if (typeEntry.value.get('hasLevels').value) {
                  <div class="level-group-row">
                    @for (levelEntry of getLevelsControls(typeEntry.key) | keyvalue; track levelEntry.key) {
                      <mat-form-field appearance="fill" class="w-100 mb-3">
                        <mat-label>{{ getDisplayLevelName(levelEntry.key) }}</mat-label>
                        <mat-select>
                          @for (type of TrelloCoverColors; track type) {
                            <mat-option [value]="type">
                              {{ ('form.teams.integrations.colors.' + type) | translate }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    }
                  </div>
                } @else {
                  <mat-form-field appearance="fill" class="w-100 mb-3">
                    <mat-label>{{ getDisplayLevelName(typeEntry.key) }}</mat-label>
                    <mat-select>
                      @for (type of TrelloCoverColors; track type) {
                        <mat-option [value]="type">
                          {{ ('form.teams.integrations.colors.' + type) | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
              </div>
            }
          </div>
        </div>
      </div>

      <div formArrayName="lists" class="my-3">
        <h3>{{ 'form.teams.integrations.lists.label' | translate }}</h3>

        @for (control of trelloLists.controls; track control; let list = $index) {
          <div [formGroupName]="list" class="list-row">
            <app-input
              formControlName="listName"
              [config]="{ id: 'listName-' + list, label: 'teams.integrations.list_name', class: 'list-item padding' }"
              [errors]="control.get('listName').errors"
              [required]="true">
            </app-input>
            <div class="horizontal-line-separator"></div>
            <app-input
              formControlName="listValue"
              [config]="{ id: 'listValue-' + list, label: 'teams.integrations.list_value', class: 'list-item padding' }"
              [errors]="control.get('listValue').errors"
              [required]="true">
            </app-input>

            @if (list > 0) {
              <button
                type="button"
                mat-icon-button
                (click)="removeTrelloListValidator(list)"
                matTooltip="{{ 'teams.integrations.remove' | translate }}"
              >
                <mat-icon>delete</mat-icon>
              </button>
            }
          </div>
        }

        <button type="button" mat-flat-button color="primary" (click)="addTrelloListValidator()">
          {{ 'form.teams.integrations.add_list.label' | translate }}
        </button>
      </div>

      <div class="d-flex flex-wrap justify-content-between my-3">
        <div>
          <h3>{{ 'form.teams.integrations.trigger_lists.label' | translate }}</h3>
          <mat-form-field appearance="fill" class="w-100 mb-3">
            <mat-select multiple formControlName="trigger_lists" required>
              @for (list of getListsValues(); track list) {
                <mat-option [value]="list">{{ list }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div>
        <button
          mat-flat-button
          color="primary"
          type="submit"
          [disabled]="configForm.invalid"
        >
          {{ 'form.teams.integrations.save.label' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #progressBar>
  <div class="d-flex align-items-center">
    <span>0%</span>
    <mat-progress-bar [mode]="upload?.state === 'PENDING' ? 'buffer' : 'determinate'" [value]="upload?.progress"
                      class="mx-2">
    </mat-progress-bar>
    <span>100%</span>
  </div>
</ng-template>

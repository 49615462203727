import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
  ButtonConfig,
  InputConfig,
} from '../../../core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => InputComponent),
  }],
  standalone: false,
})
export class InputComponent implements AfterViewInit, DoCheck, ControlValueAccessor {
  @Input() config: InputConfig;
  @Input() buttonConfig: ButtonConfig;
  @Input() errors: any;
  @Input() valid: boolean;
  @Input() isDisabled = false;
  @Input() autocapitalize = false;
  @Input() required = false;

  @Output() clickButton: EventEmitter<boolean> = new EventEmitter<boolean>;

  inputField = new FormControl('');
  private onChange: (value: string) => void;
  private onTouched: () => void;

  ngAfterViewInit(): void {
    this.inputField.valueChanges.subscribe(value => {
      if (value) {
        this.onChange(value.trim());
      } else {
        this.onChange('');
      }
    });
    this.setDisabledState(this.isDisabled);
  }

  ngDoCheck() {
    if (this.valid) {
      this.inputField.setErrors(null);
    } else {
      this.inputField.setErrors(this.errors);
    }
  }

  handleOnBlur() {
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.inputField.setValue(obj);
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputField.disable();
    } else {
      this.inputField.enable();
    }
  }
}

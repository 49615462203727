export interface TrelloConfig {
  id: number;
  key: string;
  token: string;
  board_name: string;
  members: any;
  lists: any;
  custom_fields: any;
  trigger_lists: any;
  is_private_discussion: boolean;
  created_at?: Date;
  updated_at?: Date;
  team_id: number;
  name: string;
  ticket_config_id: number;
}

export const TrelloCoverColors = [
  'green',
  'yellow',
  'orange',
  'red',
  'purple',
  'blue',
  'sky',
  'lime',
  'pink',
  'black',
];

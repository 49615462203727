import {
  Injectable,
  TemplateRef,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private headerButtonsSubject = new BehaviorSubject<TemplateRef<any> | null>(null);
  headerButtons$ = this.headerButtonsSubject.asObservable();

  setHeaderButtons(template: TemplateRef<any> | null) {
    this.headerButtonsSubject.next(template);
  }
}

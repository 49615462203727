<ng-template #headerButtons>
  @if (isTeamHead) {
    <a class="action-button" routerLink="/projects/add" mat-flat-button color="primary" type="button">
      {{'projects.create.title' | translate}}
      <mat-icon>work</mat-icon>
    </a>
  }
</ng-template>

<div class="page-container">
  <div>
    <div class="spinner" *ngIf="datasource.loading$ | async">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
      </mat-progress-spinner>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="datasource" aria-label="projects" matSort>
        <ng-container matColumnDef="name">
          <th class="w-15" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{'projects.name' | translate}}
          </th>
          <td mat-cell *matCellDef="let project"> {{project.name}} </td>
        </ng-container>
        <ng-container matColumnDef="key">
          <th class="w-15" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{'projects.key' | translate}}
          </th>
          <td mat-cell *matCellDef="let project"> {{project.key}} </td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th class="w-15" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{'projects.created_at' | translate}}
          </th>
          <td mat-cell *matCellDef="let project"> {{project.created_at | date}} </td>
        </ng-container>
        <ng-container matColumnDef="team_id">
          <th class="w-15" scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'projects.team_id' | translate }}
          </th>
          <td mat-cell *matCellDef="let project">
            {{ teamMap.get(project.team_id) || '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th class="w-10 text-right" scope="row" mat-header-cell *matHeaderCellDef disabled>
            {{'actions.action' | translate}}
          </th>
          <td mat-cell *matCellDef="let project" class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              <button mat-icon-button color="accent" type="button" routerLink="/projects/{{project.id}}">
                <mat-icon class="project-view-icon">remove_red_eye</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator #pagination
                   [pageSizeOptions]="pageSizeOptions"
                   [length]="datasource.total$ | async">
    </mat-paginator>
  </div>
</div>

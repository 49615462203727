import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import {
  AppService,
  TeamFilterService,
  Ticket,
  TicketsService,
  User,
  UserRoles,
  UserService,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import {
  DataTableComponent,
  ProjectSelectionDialogComponent,
} from '../../components';
import { LayoutService } from '../../services';

@Component({
  selector: 'app-tickets-listing',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  standalone: false,
})
export class TicketsComponent extends DataTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('headerButtons', { static: true }) headerButtons!: TemplateRef<any>;

  loading = false;
  user: User;
  displayedColumns: Array<string> = [
    'title',
    'source',
    'content',
    'creator',
    'created_at',
    'action',
  ];
  datasource: AjaxDataSource<Ticket>;
  defaultFilters: { q: string } = { q: '' };
  filterService: string = 'ticketsService';
  filterStorage: string = '_filterAdminTickets';
  tickets: Ticket[] = [];
  selectedTeamId: number | null = null;

  constructor(
    private readonly appService: AppService,
    private readonly userService: UserService,
    private readonly ticketsService: TicketsService,
    private readonly teamFilterService: TeamFilterService,
    private readonly layoutService: LayoutService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {
    super();
    this.appService.title = 'pages.tickets.list';
    this.appService.active = 'tickets';
    this.datasource = new AjaxDataSource<Ticket>();
  }

  reload() {
    let params = this.ajaxParams.getHttpParams();

    if (this.selectedTeamId != null) {
      params = params.append('team_id', this.selectedTeamId.toString());
    }
    this.updateDataSource(this.ticketsService.fetchTickets(params));
  }

  ngOnInit(): void {
    this.userService.currentUser.subscribe((user) => {
      this.user = user;
    });
    this.teamFilterService.selectedTeam$
      .subscribe((teamId) => {
        this.selectedTeamId = teamId;
        this.reload();
      });
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.layoutService.setHeaderButtons(this.headerButtons);
  }

  ngOnDestroy() {
    this.layoutService.setHeaderButtons(null);
  }

  isEditAllowed(ticket: Ticket): boolean {
    return (
      this.user.role === UserRoles.ADMIN || ticket.created_by === this.user.id
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ProjectSelectionDialogComponent);

    dialogRef.afterClosed().subscribe((projectId) => {
      if (projectId !== undefined) {
        this.router.navigateByUrl(`/projects/${projectId}/tickets/add`);
      }
    });
  }

  getProjectId(): number | null {
    if (this.user.role === UserRoles.CUSTOMER && this.user.customer_projects) {
      return this.user.customer_projects.id;
    }

    return null;
  }
}

<ng-template #headerButtons>
  @if (getProjectId()) {
    <a class="action-button" routerLink="/projects/{{ getProjectId() }}/tickets/add" mat-flat-button color="primary"
       type="button">
      {{ 'tickets.create.title' | translate }}
      <mat-icon>add</mat-icon>
    </a>
  } @else {
    <button class="action-button" mat-flat-button color="primary" type="button" (click)="openDialog()">
      {{ 'tickets.create.title' | translate }}
      <mat-icon>add</mat-icon>
    </button>
  }
</ng-template>

<div class="page-container">
  <div>
    <div class="spinner" *ngIf="datasource.loading$ | async">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
      </mat-progress-spinner>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="datasource" aria-label="tickets" matSort>
        <ng-container matColumnDef="title">
          <th class="w-20" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'tickets.title' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket">
            {{ ticket.title }}
            <mat-icon *ngIf="!ticket.seen_at" color="warn">
              fiber_new
            </mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="source">
          <th class="w-5" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'tickets.show.source' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.source }}</td>
        </ng-container>
        <ng-container matColumnDef="content">
          <th class="w-30" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'tickets.content' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.content }}</td>
        </ng-container>
        <ng-container matColumnDef="creator">
          <th class="w-15" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'tickets.show.creator' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.creator.username }}</td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th class="w-15" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'tickets.created_at' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.created_at | date }}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th class="w-10 text-right" scope="row" mat-header-cell *matHeaderCellDef disabled>
            {{ 'actions.action' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket" class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              @if (isEditAllowed(ticket)) {
                <button mat-icon-button color="accent" type="button" routerLink="/tickets/{{ticket.id}}/edit">
                  <mat-icon class="ticket-edit-icon">edit</mat-icon>
                </button>
              }
              <button
                mat-icon-button
                color="accent"
                type="button"
                routerLink="/tickets/{{ticket.id}}"
                [queryParams]="ticket"
              >
                <mat-icon class="ticket-view-icon">remove_red_eye</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator #pagination
                   [pageSizeOptions]="pageSizeOptions"
                   [length]="datasource.total$ | async">
    </mat-paginator>
  </div>
</div>


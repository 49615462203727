import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  Project,
  ProjectsService,
  Ticket,
  TicketsService,
  User,
  UserService,
} from '../../../../core';
import { AjaxDataSource } from '../../../../core/services/ajax.datasource';
import { DataTableComponent } from '../../../components';

@Component({
  selector: 'app-project-view',
  templateUrl: './view.component.html',
  styleUrl: './view.component.scss',
  standalone: false,
})
export class ProjectViewComponent extends DataTableComponent implements AfterViewInit, OnInit{
  datasource: AjaxDataSource<Ticket>;
  projectId: number | undefined;
  project: Project;
  user: User;
  tickets: Array<Ticket>;
  loading = false;
  displayedColumns: Array<string> = ['title', 'content', 'action'];
  defaultFilters: {} = {};
  filterService: string = 'projectsService';
  filterStorage: string = '_filterAdminProjectView';
  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private userService: UserService,
    private projectsService: ProjectsService,
    private ticketsService: TicketsService,
  ) {
    super();
    this.appService.title = 'pages.projects.show';
    this.appService.active = 'projects';
    this.datasource = new AjaxDataSource<Ticket>();
  }

  ngOnInit(): void {
    this.projectId = parseInt(this.route.snapshot.paramMap.get('id'));
    if (this.projectId) {
      this.projectsService.getProject(this.projectId).subscribe(data => {
        this.project = data;
        this.appService.title = this.translateService.instant('pages.projects.show', {
          name: this.project.name,
        });
      });
    }
  }

  reload() {
    if (this.projectId) {
      let params = this.ajaxParams.getHttpParams();
      params = params.set('source', this.projectId.toString());
      const service = this.ticketsService.fetchTickets(params);
      this.updateDataSource(service);
      service.subscribe(data => {
        this.tickets = data.list;
      });
    }
  }
}

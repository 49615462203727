import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { BaseService } from './base.service';

import {
  PaginationContent,
  SlackConfig,
} from '../models';
@Injectable({
  providedIn: 'root',
})
export class SlackConfigsService extends BaseService {

  protected readonly urlPath: string = '/integrations/slack/configs';

  constructor(
    private apiService: ApiService,
  ) {
    super();
  }

  getSlackConfig(id: number | string) {
    return this.apiService.get<SlackConfig>(`${this.urlPath}/${id}`).pipe(map(response => response.data));
  }

  fetchSlackConfigs(params?: HttpParams) {
    if (!params) {
      params = new HttpParams();
    }

    return this.apiService.get<PaginationContent<SlackConfig>>(this.urlPath, params).pipe(map(response => response.data));
  }

  createSlackConfig(params: object) {
    return this.apiService.post<SlackConfig>(this.urlPath, params, false).pipe(map(
      response => {
        if (response.data) {
          return response.data;
        }
      }
    ));
  }

  deleteSlackConfig(id: number) {
    return this.apiService.delete(`${this.urlPath}/${id}`);
  }
}

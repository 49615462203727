import { HttpParams } from '@angular/common/http';
import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';

import { forkJoin } from 'rxjs';

import {
  AppService,
  HelperPrepareParamsService,
  ProjectsService,
  SlackConfig,
  SlackConfigsService,
  Team,
  TeamFilterService,
  TeamsService,
  TicketConfig,
  TicketConfigsService,
  ToastsService,
  TrelloConfig,
  TrelloConfigsService,
  User,
} from '../../../../core';

@Component({
  selector: 'app-admin-project-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
  standalone: false,
})
export class ProjectCreateComponent implements OnInit{
  createProject: FormGroup;
  user: User;
  teams: Team[] = [];
  trelloConfigs: TrelloConfig[] = [];
  ticketConfigs: TicketConfig[] = [];
  slackConfigs: SlackConfig[] = [];
  selectedTeamId: number | null = null;
  selectedTicketConfigId: number | null = null;

  constructor(
    private appService: AppService,
    private fb: FormBuilder,
    private router: Router,
    private projectsService: ProjectsService,
    private slackConfigsService: SlackConfigsService,
    private ticketConfigsService: TicketConfigsService,
    private trelloConfigsService: TrelloConfigsService,
    private teamsService: TeamsService,
    private toastsService: ToastsService,
    private teamFilterService: TeamFilterService
  ) {
  }

  ngOnInit() {
    this.appService.title = 'projects.create.title';
    this.createProject = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      key: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      team_id: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      ticket_config_id: [{ value: null, disabled: true }, [Validators.required, Validators.pattern('^[0-9]+$')]],
      trello_config_id: [{ value: null, disabled: true }, [Validators.pattern('^[0-9]+$')]],
      slack_config_id: [{ value: null, disabled: true }, [Validators.pattern('^[0-9]+$')]],
    });

    this.teamFilterService.selectedTeam$.subscribe(selectedTeam => {
      if (selectedTeam) {
        this.createProject.patchValue({ team_id: selectedTeam });
        this.teamUpdate(selectedTeam);
      }
    });
    this.teamsService.fetchTeams().subscribe(result => {
      this.teams = result.list;
    });
  }

  onTeamChange(event: MatSelectChange) {
    const teamId = event.value;

    // Reset dependent selects
    this.createProject.patchValue({
      ticket_config_id: null,
      trello_config_id: null,
      slack_config_id: null,
    });
    this.teamUpdate(teamId);
  }

  teamUpdate(teamId?: number){
    if (teamId) {
      this.selectedTeamId = teamId;
      this.loadTicketConfigsForTeam(teamId);
      this.createProject.get('ticket_config_id').enable();
    } else {
      this.selectedTeamId = null;
      this.createProject.get('ticket_config_id').disable();
      this.createProject.get('trello_config_id').disable();
      this.createProject.get('slack_config_id').disable();
      this.ticketConfigs = [];
      this.trelloConfigs = [];
      this.slackConfigs = [];
    }
  }

  loadTicketConfigsForTeam(teamId: number) {
    if (!teamId) return;

    const filters = new HttpParams()
      .set('team_id', teamId)
      .set('per_page', 50);

    this.ticketConfigsService.fetchTicketConfigs(filters).subscribe({
      next: (configs) => {
        this.ticketConfigs = configs.list;
      },
      error: (error) => {
        this.toastsService.add(error);
      },
    });
  }

  onTicketConfigChange(event: MatSelectChange) {
    const ticketConfigId = event.value;

    // Reset dependent selects
    this.createProject.patchValue({
      trello_config_id: null,
      slack_config_id: null,
    });

    if (ticketConfigId) {
      this.selectedTicketConfigId = ticketConfigId;
      this.loadRelatedConfigs(ticketConfigId);
    } else {
      this.selectedTicketConfigId = null;
      this.createProject.get('trello_config_id').disable();
      this.createProject.get('slack_config_id').disable();
      this.trelloConfigs = [];
      this.slackConfigs = [];
    }
  }

  loadRelatedConfigs(ticketConfigId: number) {
    if (!ticketConfigId) return;

    // Enable the related config controls
    this.createProject.get('trello_config_id').enable();
    this.createProject.get('slack_config_id').enable();

    const filters = new HttpParams()
      .set('team_id', this.selectedTeamId)
      .set('ticket_config_id', ticketConfigId)
      .set('per_page', 50);

    forkJoin({
      trello: this.trelloConfigsService.fetchTrelloConfigs(filters),
      slack: this.slackConfigsService.fetchSlackConfigs(filters),
    }).subscribe({
      next: (result) => {
        this.trelloConfigs = result.trello.list;
        this.slackConfigs = result.slack.list;
      },
      error: (error) => {
        this.toastsService.add(error);
      },
    });
  }

  onSubmit() {
    if (this.createProject.valid) {
      const projectCreateParams = HelperPrepareParamsService.prepareParams(this.createProject.getRawValue());
      const service = this.projectsService.createProject(projectCreateParams);
      service.subscribe({
        next: project => {
          this.router.navigateByUrl('/projects/' + project.id);
        },
      });
    }
  }
}

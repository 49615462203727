import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ValidationService } from '../../../core';
import { HidePassword } from '../../../core/models/icon.model';

@Component({
  selector: 'app-password-change',
  templateUrl: 'password-change-dialog.component.html',
  standalone: false,
})
export class PasswordChangeDialogComponent implements AfterContentChecked{
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  hidePassword = true;
  hideNewPassword = true;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [
        Validators.required,
        Validators.minLength(6),
        this.validationService.differValidator('old_password'),
      ],
      ],
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.onSubmit.emit(Object.assign({}, this.form.getRawValue()));
  }

  getPassIcon(isHide: boolean): string {
    return isHide ? HidePassword.OFF : HidePassword.ON;
  }
}

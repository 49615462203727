import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  SlackConfig,
  SlackConfigsService,
  ToastsService,
} from '../../../../../core';
import { AjaxDataSource } from '../../../../../core/services/ajax.datasource';
import { DataTableComponent } from '../../../../components';

@Component({
  selector: 'app-slack-config-listing',
  templateUrl: './slack.component.html',
  styleUrl: './slack.component.scss',
  standalone: false,
})
export class SlackConfigComponent extends DataTableComponent implements AfterViewInit, OnInit {
  loading = false;
  displayedColumns: Array<string> = ['id', 'name', 'action'];
  teamId: number | undefined;
  datasource: AjaxDataSource<SlackConfig>;
  defaultFilters: { q: string } = { q: '' };
  filterService: string = 'trelloConfigService';
  filterStorage: string = '_filterTrelloConfig';

  constructor(
    private appService: AppService,
    private slackConfigService: SlackConfigsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastsService: ToastsService,
    private translateService: TranslateService
  ) {
    super();
    this.appService.title = 'teams.integrations.slack_config';
    this.appService.active = 'slackConfig';
    this.datasource = new AjaxDataSource<SlackConfig>();
  }

  ngOnInit(): void {
    this.teamId = parseInt(this.route.snapshot.paramMap.get('id'));
  }

  navigateToSlackCreate(): void {
    this.router.navigateByUrl(`/teams/${this.teamId}/integrations/slack/create`);
  }

  navigateToSlackEdit(id: number): void {
    this.router.navigateByUrl(`/teams/${this.teamId}/integrations/slack/${id}/edit`);
  }

  deleteSlackConfig(id: number): void {
    this.slackConfigService.deleteSlackConfig(id).subscribe({
      next: () => {
        this.reload();
        this.toastsService.add(this.translateService.instant('teams.integrations.slack_config_deleted'));
      },
      error: (err) => {
        this.toastsService.add(err);
      },
    });
  }

  reload() {
    this.updateDataSource(
      this.slackConfigService.fetchSlackConfigs(
        this.ajaxParams.getHttpParams().set('order[]', 'id')
      )
    );
  }
}

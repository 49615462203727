<div class="page-container">
  <div>
    @if (datasource.loading$ | async) {
      <div class="spinner">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </div>
    }
    <div class="row mb-3">
      <div class="col d-flex align-items-center justify-content-end gap-1 mt-md-3 mt-lg-0">
        <button class="action-button" mat-flat-button color="primary" type="button" (click)="navigateInvitePage()">
          {{ 'teams.show.invite_button' | translate }}
          <mat-icon>person</mat-icon>
        </button>
        @if (currentMember) {
          <button class="leave-team-button error-button" mat-stroked-button type="button"
                  (click)="openLeaveTeamModal()">
            {{ 'teams.show.leave_team' | translate }}
            <mat-icon>cancel</mat-icon>
          </button>
        }
        <button *ngIf="!isAdminApp" class="action-button" mat-flat-button color="primary" [matMenuTriggerFor]="integrationMenu">
          {{ 'teams.show.configure_integrations_button' | translate }}
          <mat-icon>settings</mat-icon>
        </button>

        <mat-menu #integrationMenu="matMenu">
          <button mat-menu-item (click)="navigateToSlack()">
            {{ 'teams.show.slack_integration' | translate }}
            <mat-icon svgIcon="slack"></mat-icon>
          </button>
          <button mat-menu-item (click)="navigateToTrello()">
            {{ 'teams.show.trello_integration' | translate }}
            <mat-icon svgIcon="trello"></mat-icon>
          </button>
          <button mat-menu-item (click)="navigateToTicket()">
            {{ 'teams.show.ticket_integration' | translate }}
            <mat-icon>assignment</mat-icon>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="datasource" aria-label="team-members" matSort>
        <ng-container matColumnDef="avatar">
          <th class="w-5" scope="row" mat-header-cell *matHeaderCellDef>
            {{ 'form.avatar.label' | translate }}
          </th>
          <td mat-cell *matCellDef="let teamMember">
            <div class="avatar">
              <app-avatar class="d-flex" [user]="teamMember.member" [width]="40" [preset]="avatarSize"></app-avatar>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="first_name">
          <th class="w-25" scope="row" mat-header-cell *matHeaderCellDef>
            {{ 'teams.show.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let teamMember">
            {{ teamMember.member.first_name + ' ' + teamMember.member.last_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th class="w-25" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'teams.show.role' | translate }}
          </th>
          <td mat-cell *matCellDef="let teamMember"> {{ teamMember.role }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th class="w-25" scope="row" mat-header-cell *matHeaderCellDef>
            {{ 'teams.show.email' | translate }}
          </th>
          <td mat-cell *matCellDef="let teamMember"> {{ teamMember.member.email }}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th class="w-15 text-right" scope="row" mat-header-cell *matHeaderCellDef disabled>
            {{ 'actions.action' | translate }}
          </th>
          <td mat-cell *matCellDef="let teamMember" class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              @if (canRemoveMembers) {
                <button (click)="openRemoveTeamMemberModal(teamMember)" mat-icon-button type="button"
                        class="ms-2 delete-icon">
                  <mat-icon class="user-delete-icon">delete</mat-icon>
                </button>
              }
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator #pagination
                   [pageSizeOptions]="pageSizeOptions"
                   [length]="datasource.total$ | async">
    </mat-paginator>
  </div>
</div>

import {
  Component,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  TicketConfig,
  TicketConfigsService,
  ToastsService,
  User,
} from '../../../../../../core';
import { AjaxDataSource } from '../../../../../../core/services/ajax.datasource';
import { ConfirmDialogComponent } from '../../../../../components';

@Component({
  selector: 'app-ticket-config-view',
  templateUrl: './view.component.html',
  styleUrl: './view.component.scss',
  standalone: false,
})
export class TicketConfigViewComponent implements OnInit{
  datasource: AjaxDataSource<TicketConfig>;
  ticketConfigId: number | undefined;
  teamId: number | undefined;
  ticketConfig: TicketConfig;
  user: User;
  loading = false;
  displayedColumns: Array<string> = ['id', 'name', 'custom_fields', 'action'];
  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private ticketConfigService: TicketConfigsService,
    private  toastsService: ToastsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.appService.title = 'teams.integrations.ticket_config';
    this.appService.active = 'slackConfig';
    this.datasource = new AjaxDataSource<TicketConfig>();
  }

  ngOnInit(): void {
    this.ticketConfigId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.teamId = parseInt(this.route.snapshot.paramMap.get('teamId'));
    if (this.ticketConfigId) {
      this.ticketConfigService.getTicketConfig(this.ticketConfigId).subscribe({
        next: (data) => {
          this.ticketConfig = data;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.translateService.get('teams.integrations.config_load').subscribe((translatedMessage) => {
            this.toastsService.add(translatedMessage);
          });
        },
      });
    } else {
      this.loading = false;
    }
  }

  openDeleteDialog(): void {
    const dialogData = {
      title: this.translateService.instant('teams.integrations.deleteConfig.title'),
      message: this.translateService.instant('teams.integrations.deleteConfig.message'),
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteTicketConfig();
      }
    });
  }

  deleteTicketConfig(): void {
    if (this.ticketConfigId) {
      this.ticketConfigService.deleteTicketConfig(this.ticketConfigId).subscribe({
        next: () => {
          this.router.navigate([`/teams/${this.teamId}/integrations/ticket`]);
          this.snackBar.open(
            this.translateService.instant('teams.integrations.deleteConfig.success'),
            this.translateService.instant('teams.integrations.deleteConfig.close'),
            { duration: 3000 }
          );
        },
        error: () => {
          this.snackBar.open(
            this.translateService.instant('teams.integrations.deleteConfig.error'),
            this.translateService.instant('teams.integrations.deleteConfig.close'),
            { duration: 3000 }
          );
        },
      });
    }
  }
}

<mat-dialog-content>
  <p>Select your project</p>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ label }}</mat-label>
    <mat-select #projectSelect [formControl]="projectCtrl" disableOptionCentering>
      <mat-option>
        <ngx-mat-select-search
          #searchInput
          [formControl]="searchCtrl"
          [searching]="loading"
          [hideClearSearchButton]="true"
          [placeholderLabel]="'Enter project name...'"
          [noEntriesFoundLabel]="'No projects found'"
          (scrolledToBottom)="onScrollToBottom()">
        </ngx-mat-select-search>
      </mat-option>

      @for (project of filteredProjects | async; track project.id) {
        <mat-option [value]="project.id">
          {{ project.name }}
        </mat-option>
      }
      @if (loading) {
        <mat-option disabled>
          <div class="d-flex align-items-center justify-content-center">
            <mat-spinner diameter="20"></mat-spinner>
            <span class="ml-2">Loading...</span>
          </div>
        </mat-option>
      }
    </mat-select>
    @if (projectCtrl.hasError('required')) {
      <mat-error>Please select a project</mat-error>
    }
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" type="button">Close</button>
  <button mat-button (click)="submit()" cdkFocusInitial>Create Ticket</button>
</mat-dialog-actions>

import { HttpParams } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  Subject,
  takeUntil,
} from 'rxjs';

import {
  Notification,
  NotificationsService,
  NotificationStatistic,
  NotificationType,
  PaginationContent,
  User,
  UserService,
} from '../../../core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: false,
})
export class NotificationComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  statistic: number = 0;
  notifications: Array<Notification> = [];
  user: User;

  constructor(private notificationsService: NotificationsService, private userService: UserService) {
    this.getNotificationStatistic();
  }

  ngOnInit(): void {
    this.userService.currentUser.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user: User): void => {
        this.user = user;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getNotificationStatistic(): void {
    this.notificationsService.getStatistic().subscribe({
      next: (value: NotificationStatistic): void => {
        this.statistic = value.count;
      },
    });
  }

  getNotifications(): void {
    this.notificationsService.fetchNotifications(this.getParams()).subscribe({
      next: (response: PaginationContent<Notification>): void => {
        this.notifications = response.list;
      },
    });
  }

  markRead(id: number): void {
    this.notificationsService.readNotification(id).subscribe({
      next: (notification: Notification): void => {
        this.notifications.forEach((n: Notification): void => {
          if (n.id === notification.id) {
            n.seen_at = notification.seen_at;
          }
        });
        --this.statistic;
      },
    });
  }

  private getParams(): HttpParams {
    return (new HttpParams()).appendAll({ user_id: this.user.id, type: NotificationType.PRIMARY });
  }
}

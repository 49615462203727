import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  EMPTY,
  Observable,
  shareReplay,
} from 'rxjs';

import {
  ApiService,
  SlackConfigsService,
  TicketDefaultKey,
  Upload,
} from '../../../../../../core';
import { IntegrationConfigFormComponent } from '../../../../../components';

@Component({
  selector: 'app-integrations',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  standalone: false,
})
export class SlackConfigCreateComponent extends IntegrationConfigFormComponent implements OnInit {
  @ViewChild('progressBar') progressBar: TemplateRef<any>;
  @ViewChild('fileInputApp') fileInputApp: ElementRef;

  override descriptionFields = ['color', 'description', 'label'];

  upload$: Observable<Upload> = EMPTY;
  projectId: number;
  preview: any;
  previewApp: any;
  upload: { state: string; progress: number };

  constructor(
    private apiService: ApiService,
    private slackConfigService: SlackConfigsService,
    private _snackBar: MatSnackBar,
  ) {
    super();

    this.initializeForm();
    this.appService.title = 'pages.teams.integrations';
    this.appService.active = 'teams';
  }

  initializeForm(): void {
    this.configForm = this.fb.group({
      token: ['', [Validators.required]],
      channel_id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      members: this.fb.group({}),
      description_fields: this.fb.group({
        color: this.fb.group({}),
        description: this.fb.group({}),
        label: this.fb.group({}),
      }),
      custom_fields: this.fb.array([]),
      is_private_discussion: [false, Validators.required],
      team_id: [null, Validators.required],
      ticket_config_id: [null, Validators.required],
      app_icon: this.fb.group({
        id: [null],
        url: [''],
        mime: [''],
        presets: this.fb.array([]),
      }),
    });

    this.addMemberType(TicketDefaultKey);
    this.addMemberToType(TicketDefaultKey);
  }

  addMemberType(typeName: string): void {
    const membersGroup = this.membersFormGroup;

    if (!membersGroup.contains(typeName)) {
      const hasLevels = this.typeRequiresLevel(typeName);

      const typeGroup: FormGroup = this.fb.group({
        hasLevels: [hasLevels],
        participants: this.fb.array([]),
        groupingAdded: [hasLevels],
      });

      if (hasLevels) {
        typeGroup.addControl('levels', this.fb.group({}));
      }

      membersGroup.addControl(typeName, typeGroup);

      // Add description fields
      const descriptionFields = this.configForm.get('description_fields') as FormGroup;
      this.descriptionFields.forEach(field => {
        const fieldGroup = descriptionFields.get(field) as FormGroup;
        const defaultValue = field === 'color' ? '#000fff' : '';

        if (hasLevels) {
          // Add as group for levels
          fieldGroup.addControl(typeName, this.fb.group({}));

          // If we have ticket config with levels, add them
          if (this.selectedTicketConfig?.custom_fields?.level) {
            const typeFieldGroup = fieldGroup.get(typeName) as FormGroup;
            this.selectedTicketConfig.custom_fields.level.forEach(level => {
              typeFieldGroup.addControl(level.toString(), this.fb.control(defaultValue));
            });
          } else {
            // Fallback
            (fieldGroup.get(typeName) as FormGroup).addControl('1', this.fb.control(defaultValue));
          }
        } else {
          // Add as simple control
          fieldGroup.addControl(typeName, this.fb.control(defaultValue));
        }
      });

      // If type requires levels, set them up
      if (hasLevels) {
        this.setupLevelsForType(typeName);
      }
    } else {
      this.toastsService.add(this.translate.instant('teams.integrations.config_load', { typeName: typeName }));
    }
  }

  onSubmit(form: FormGroup): void {
    if (!form.valid) {
      this.toastsService.add(this.translate.instant('teams.integrations.failed_form'));
    }

    this.isSubmitting = true;
    this.slackConfigService.createSlackConfig(this.prepareParams(form.value)).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.router.navigate([`/teams/${this.teamId}/integrations/slack`]);
      },
      error: () => {
        this.isSubmitting = false;
        this.toastsService.add(this.translate.instant('teams.integrations.failed_save'));
      },
    });
  }

  openProgressBar(): void {
    this._snackBar.openFromTemplate(this.progressBar);
  }

  dismissProgressBar(): void {
    setTimeout(() => this._snackBar.dismiss(), 1000);
  }

  uploadIcon(files: FileList | null, iconType: string): void {
    if (!files || this.isSubmitting) {
      return;
    }

    const file = files.item(0);
    if (!this.isValidIcon(file)) {
      return;
    }
    const reader = new FileReader();
    reader.onload = e => {
      if (iconType === 'app_icon') {
        this.previewApp = e.target.result;
      }
    };
    reader.readAsDataURL(file);

    this.sendIcon(file, iconType);
  }

  isValidIcon(file: File): boolean {
    if (file.size > this.apiService.ALLOWED_IMAGE_SIZE) {
      this.toastsService.add(this.translate.instant('validations.avatar.size'));
      return false;
    }

    if (!this.apiService.ALLOWED_IMAGE_TYPES.includes(file.type)) {
      this.toastsService.add(this.translate.instant('validations.avatar.type'));
      return false;
    }

    return true;
  }

  sendIcon(file: File, iconType: string): void {
    this.upload$ = this.apiService.upload(file).pipe(shareReplay());
    this.isSubmitting = true;
    this.openProgressBar();

    this.upload$.subscribe({
      next: (fileResponse) => {
        if (fileResponse.state === 'DONE') {
          this.dismissProgressBar();
          this.isSubmitting = false;

          this.configForm.get(iconType)?.patchValue({
            id: fileResponse.data.id,
            url: fileResponse.data.url,
            mime: fileResponse.data.mime,
            presets: fileResponse.data.presets,
          });
        }
      },
      error: () => {
        this.dismissProgressBar();
        this.isSubmitting = false;
      },
    });
  }

  prepareParams(formValue: any): any {
    const preparedValue = { ...formValue };
    const members = preparedValue.members;
    const preparedMembers = {};

    for (const typeName in members) {
      const typeGroup = members[typeName];
      if (typeGroup.hasLevels) {
        const levelsGroup = typeGroup.levels;
        preparedMembers[typeName] = {};

        for (const levelNumber in levelsGroup) {
          const participantsArray = levelsGroup[levelNumber];
          preparedMembers[typeName][levelNumber] = participantsArray.map(
            (member: any) => this.getMemberName(member)
          );
        }
      } else {
        const participantsArray = typeGroup.participants;
        preparedMembers[typeName] = participantsArray.map((member: any) => this.getMemberName(member));
      }
    }

    if (preparedValue.description_fields) {
      const descriptionFields = preparedValue.description_fields;
      const filteredDescriptionFields = {};

      this.descriptionFields.forEach((field: string): void => {
        const fieldGroup = descriptionFields[field];
        const filteredField = {};

        for (const typeName in fieldGroup) {
          if (typeof fieldGroup[typeName] === 'object') {
            const levels = fieldGroup[typeName];
            const filteredLevels = {};
            for (const levelNumber in levels) {
              if (levels[levelNumber] && levels[levelNumber].trim() !== '') {
                filteredLevels[levelNumber] = levels[levelNumber];
              }
            }
            if (Object.keys(filteredLevels).length) {
              filteredField[typeName] = filteredLevels;
            }
          } else {
            if (fieldGroup[typeName] && fieldGroup[typeName].trim() !== '') {
              filteredField[typeName] = fieldGroup[typeName];
            }
          }
        }

        if (Object.keys(filteredField).length) {
          filteredDescriptionFields[field] = filteredField;
        }
      });

      if (Object.keys(filteredDescriptionFields).length) {
        preparedValue.description_fields = filteredDescriptionFields;
      } else {
        delete preparedValue.description_fields;
      }
    }

    if (preparedValue.custom_fields) {
      preparedValue.custom_fields = preparedValue.custom_fields
        .filter(field => field.selected)
        .map(field => field.name);
    }

    // Remove app_icon if no id is set
    if (preparedValue.app_icon && (!preparedValue.app_icon.id)) {
      delete preparedValue.app_icon;
    }

    preparedValue.members = preparedMembers;
    return preparedValue;
  }

  getMemberName(member: any): string {
    if (member && member.member) {
      const firstName = member.member.first_name.replace(/\s+/g, '');
      const lastName = member.member.last_name.replace(/\s+/g, '');
      const fullName = `${firstName}${lastName}`;
      return `@${fullName}`;
    }
    return '';
  }
}

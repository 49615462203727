<div class="px-12 justify-content-center flex-form">
  <form [formGroup]="editProject" (ngSubmit)="onSubmit()">
    <div class="flex-center-form flex-column">
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.projectName.label' | translate}}</mat-label>
        <input matInput formControlName="name">
        <mat-error>
          <app-validation-error-render label="projectName"
                                       [errors]="editProject.controls.name.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.projectKey.label' | translate}}</mat-label>
        <input matInput formControlName="key">
        <mat-error>
          <app-validation-error-render label="projectKey"
                                       [errors]="editProject.get('key').errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.ticketConfig.label' | translate}}</mat-label>
        <mat-select formControlName="ticket_config_id" (selectionChange)="onTicketConfigChange($event)">
          <mat-option *ngFor="let config of ticketConfigs" [value]="config.id">
            {{config.name}}
          </mat-option>
        </mat-select>
        <mat-error>
          <app-validation-error-render label="ticketConfig"
                                       [errors]="editProject.get('ticket_config_id').errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.trelloConfig.label' | translate}}</mat-label>
        <mat-select formControlName="trello_config_id" [disabled]="!selectedTicketConfigId">
          <mat-option *ngFor="let config of trelloConfigs" [value]="config.id">
            {{config.name}}
          </mat-option>
        </mat-select>
        <mat-error>
          <app-validation-error-render label="trelloConfig"
                                       [errors]="editProject.get('trello_config_id').errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.slackConfig.label' | translate}}</mat-label>
        <mat-select formControlName="slack_config_id" [disabled]="!selectedTicketConfigId">
          <mat-option *ngFor="let config of slackConfigs" [value]="config.id">
            {{config.name}}
          </mat-option>
        </mat-select>
        <mat-error>
          <app-validation-error-render label="slackConfig"
                                       [errors]="editProject.get('slack_config_id').errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <h3>{{ 'form.rolesSection.label' | translate }}</h3>
      <div formArrayName="roles" >
        <div *ngFor="let role of roles.controls; let i = index" [formGroupName]="i" class="role-group">
          <mat-form-field appearance="fill" class="w-20">
            <mat-label>{{'form.roleName.label' | translate}}</mat-label>
            <input matInput  formControlName="name">
            <mat-error>
              <app-validation-error-render label="roleName"
                                           [errors]="role.get('name').errors">
              </app-validation-error-render>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-10">
            <mat-label>{{ 'form.access' | translate }}</mat-label>
            <mat-select formControlName="access">
              <mat-option value="accessAll">{{ 'form.accessAll' | translate }}</mat-option>
              <mat-option value="accessOwn">{{ 'form.accessOwn' | translate }}</mat-option>
              <mat-option value="noAccess">{{ 'form.noAccess' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <button *ngIf="i !== 0"  (click)="removeRole(i)" mat-icon-button type="button"
                  class="ms-2 delete-icon">
            <mat-icon class="user-delete-icon">delete</mat-icon>
          </button>
        </div>
      </div>
      <button mat-raised-button color="primary" class="w-10 mat-mdc-mb-2" type="button" (click)="addRole()">
        {{'actions.addRole' | translate}}
      </button>
    </div>
    <button mat-raised-button color="primary" type="submit" class="mat-mdc-mt-2 button-spacing" [disabled]="!editProject.valid">
      {{'actions.submit' | translate}}
    </button>
  </form>
</div>

import { Component } from '@angular/core';

import { AppService } from '../../../core';

@Component({
  selector: 'app-page-action-logs',
  templateUrl: './action-logs.component.html',
  standalone: false,
})
export class ActionLogsComponent {
  constructor(
    private appService: AppService,
  ) {
    this.appService.title = 'pages.actionLogs';
    this.appService.active = 'actionLogs';
  }
}

@if (isLoading) {
  <div class="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>
}
<div class="px-12 justify-content-center flex-form">
  <form [formGroup]="createTicket" (ngSubmit)="onSubmit()">
    <div class="flex-center-form flex-column">
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.title' | translate }}</mat-label>
        <input matInput formControlName="title">
        <mat-error>
          <app-validation-error-render
            label="title"
            [errors]="createTicket.controls.title.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.content' | translate }}</mat-label>
        <textarea matInput formControlName="content"></textarea>
        <mat-error>
          <app-validation-error-render
            label="content"
            [errors]="createTicket.controls.content.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <div formGroupName="custom_fields">
        <mat-form-field appearance="fill" class="w-60">
          <mat-label>{{ 'tickets.form.custom_fields.type.label' | translate }}</mat-label>
          <mat-select formControlName="type" (selectionChange)="onTypeChange($event)">
            @for (type of typeOptions; track type) {
              <mat-option [value]="type">{{ type }}</mat-option>
            }
          </mat-select>
          <mat-error>
            <app-validation-error-render
              label="customFields.type"
              [errors]="createTicket.get('custom_fields').get('type').errors">
            </app-validation-error-render>
          </mat-error>
        </mat-form-field>

        @for (field of getFilteredFields(); track field) {
              @if (customFieldOptions[field]?.length) {
                <mat-form-field appearance="fill" class="w-60">
                  <mat-label>{{ getTranslation(field) }}</mat-label>
                  <mat-select [formControlName]="field">
                    @for (option of customFieldOptions[field] || []; track option) {
                      <mat-option [value]="option">{{ option }}</mat-option>
                    }
                  </mat-select>
                  <mat-error>
                    <app-validation-error-render
                      [label]="'customFields.' + field"
                      [errors]="createTicket.get('custom_fields').get(field).errors">
                    </app-validation-error-render>
                  </mat-error>
                </mat-form-field>
              }
            }
      </div>
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.page_url' | translate }}</mat-label>
        <input matInput formControlName="page_url">
        <mat-error>
          <app-validation-error-render
            label="page_url"
            [errors]="createTicket.controls.page_url.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="avatar-upload">
      <div *ngIf="!preview" class="upload-container" (click)="fileInput.click()"
           (dragover)="onDragOver($event)"
           (drop)="onDrop($event)">
        <div class="upload-content">
          <mat-icon class="upload-icon">cloud_upload</mat-icon>
          <p class="upload-text">{{ 'Upload File' | translate }}</p>
        </div>
        <input hidden type="file"
               [accept]="['.jpg', '.png', '.bmp', 'jpeg']"
               #fileInput
               (change)="uploadAvatar(fileInput.files)"/>
      </div>

      <div *ngIf="preview" class="preview-container">
        <img [src]="preview" alt="Uploaded Avatar" class="avatar-preview"/>
        <button mat-icon-button class="remove-avatar" (click)="removeAvatar()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <button mat-raised-button color="primary" type="submit" [disabled]="!createTicket.valid || isLoading">
      {{ 'actions.submit' | translate }}
    </button>
  </form>
</div>

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  AppService,
  Project,
  ProjectsService,
  Team,
  TeamFilterService,
  TeamMemberRoles,
  TeamsService,
  UserRoles,
  UserService,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import { DataTableComponent } from '../../components';
import { LayoutService } from '../../services';

@Component({
  selector: 'app-projects-listing',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  standalone: false,
})
export class ProjectsComponent extends DataTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('headerButtons', { static: true }) headerButtons!: TemplateRef<any>;

  loading = false;
  displayedColumns: Array<string> = ['name', 'key', 'created_at', 'team_id', 'action'];
  datasource: AjaxDataSource<Project>;
  defaultFilters: { q: string } = { q: '' };
  filterService: string = 'projectsService';
  filterStorage: string = '_filterAdminProjects';
  teamMap: Map<number, string>;
  isTeamHead = false;
  selectedTeamId: number | null = null;

  constructor(
    private appService: AppService,
    private projectsService: ProjectsService,
    private teamsService: TeamsService,
    private userService: UserService,
    private teamFilterService: TeamFilterService,
    private layoutService: LayoutService,
  ) {
    super();
    this.appService.title = 'pages.projects.list';
    this.appService.active = 'projects';
    this.datasource = new AjaxDataSource<Project>();
    this.teamMap = new Map<number, string>();
  }

  ngOnInit(): void {
    this.teamFilterService.selectedTeam$
      .subscribe(teamId => {
        this.selectedTeamId = teamId;
        this.reload();
      });
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.loadTeamNames();
    this.checkIfMemberIsTeamHead();
    this.layoutService.setHeaderButtons(this.headerButtons);
  }

  ngOnDestroy(): void {
    this.layoutService.setHeaderButtons(null);
  }

  checkIfMemberIsTeamHead(): void {
    this.userService.currentUser.subscribe(user => {
      if (user.role === UserRoles.ADMIN) {
        this.isTeamHead = true;
      } else {
        const isHeadInAnyTeam = user.members?.some(member => member.role === TeamMemberRoles.HEAD);
        this.isTeamHead = isHeadInAnyTeam || false;
      }
    });
  }

  loadTeamNames(): void {
    this.teamsService.fetchTeams().subscribe(teams => {
      this.teamMap = teams.list.reduce((map: Map<number, string>, team: Team) => {
        map.set(team.id, team.name);
        return map;
      }, new Map<number, string>());
    });
  }

  reload(): void {
    let params = this.ajaxParams.getHttpParams();

    if (this.selectedTeamId !== null) {
      params = params.append('team_id', this.selectedTeamId.toString());
    }

    this.updateDataSource(this.projectsService.fetchProjects(params));
  }
}

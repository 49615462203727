import { Component } from '@angular/core';

import { AppService } from '../../../core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: false,
})

export class NotFoundComponent {
  constructor(private appService: AppService) {
    this.appService.title = 'pages.notFound';
    this.appService.active = null;
  }
}

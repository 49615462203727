<mat-nav-list>
  @for (item of menu; track item.id) {
    @if (item.children) {
      <mat-expansion-panel [class.mat-elevation-z0]="true" [expanded]="item.expanded">
        <mat-expansion-panel-header>
          <mat-icon>{{ item.icon }}</mat-icon>
          <span>{{ item.title | translate }}</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          @for (child of item.children; track child.id) {
            <a mat-list-item routerLink="{{child.link}}" [class.active]="active === child.id">
              <div class="d-flex">
                <mat-icon>{{ child.icon }}</mat-icon>
                <span>{{ child.title | translate }}</span>
              </div>
            </a>
          }
        </mat-nav-list>
      </mat-expansion-panel>
    }
    @if (!item.children && !item.isHidden) {
      <a mat-list-item routerLink="{{item.link}}" (click)="beforeSwitch()" [class.active]="active === item.id">
        <div class="d-flex">
          <mat-icon>{{ item.icon }}</mat-icon>
          <span matLine>{{ item.title | translate }}</span>
        </div>
      </a>
    }
  }
</mat-nav-list>

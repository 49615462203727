import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: false,
})
export class LandingComponent implements AfterViewInit, OnInit {
  constructor(private router: Router) { }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }
  howItWorks = {
    title:'How It Works',
    data:[
      {
        title: 'Report Issues and Feature Requests',
        description: 'Easily submit bug reports and feature requests directly through our intuitive dashboard, ensuring your team stays on top of priorities.',
        imgSrc: '/assets/icons/report.png',
        imgAlt: 'Report Issues',
        reverse: false,
      },
      {
        title: 'Seamless Integration with Tools',
        description: 'Integrate with services like Trello, Slack, and email to streamline communication, task management, and issue tracking across your team.',
        imgSrc: '/assets/icons/integration.png',
        imgAlt: 'Seamless Integration',
        reverse: true,
      },
      {
        title: 'Agile Configuration and Customization',
        description: "Tailor workflows to fit your team's agile methodology, ensuring that bug fixes, feature requests, and tasks align with your sprint cycles.",
        imgSrc: '/assets/icons/agile.png',
        imgAlt: 'Agile Configuration',
        reverse: false,
      },
      {
        title: 'Extendable and Scalable Solutions',
        description: "Easily extend the system by adding integrations with a wide range of services, enhancing your team's workflow as your needs grow.",
        imgSrc: '/assets/icons/extendable.png',
        imgAlt: 'Extendable Solutions',
        reverse: true,
      },
      {
        title: 'Efficient Bug Fixing and Feature Delivery',
        description: 'Track the progress of bug fixes and new feature implementations, ensuring they are delivered smoothly and quickly within your team.',
        imgSrc: '/assets/icons/bug-fixing.svg',
        imgAlt: 'Bug Fixing and Features',
        reverse: false,
      },
      {
        title: 'Track Progress in Real Time',
        description: "Monitor real-time updates and insights on the status of issues, requests, and development progress across your team's tasks.",
        imgSrc: '/assets/icons/track.png',
        imgAlt: 'Track Progress',
        reverse: true,
      },
    ],
  };

  features = {
    title: 'Why Choose Hermes?',
    data: [
      {
        icon: '/assets/icons/bug.svg',
        title: 'Easy Issue Reporting',
        description: 'Report and manage bugs with an intuitive and user-friendly interface.',
      },
      {
        icon: '/assets/icons/feature.svg',
        title: 'Feature Requests',
        description: 'Engage with users by collecting and prioritizing feature suggestions.',
      },
      {
        icon: '/assets/icons/notification.svg',
        title: 'Real-time Notifications',
        description: 'Stay informed with instant alerts for new reports and updates.',
      },
    ],
  };

  testimonials = {
    title: 'What Our Users Say',
    data:
      [
        {
          quote: '"Hermes transformed how we handle bugs and feature requests. Our workflow has never been smoother!"',
          author: 'Alex Johnson, Lead Developer',
        },
        {
          quote: '"The real-time notifications keep us updated on the most critical issues. A game changer for our team!"',
          author: 'Maria Rodriguez, Product Manager',
        },
      ],
  };

  integrations = {
    title: 'Seamless Integrations',
    description: ' Hermes connects effortlessly with your favorite tools to enhance productivity.',
    data: [
      {
        icon: '/assets/icons/slack.svg',
        title: 'Slack',
        description: 'Get real-time bug and feature updates in your Slack channels.',
      },
      {
        icon: '/assets/icons/trello.svg',
        title: 'Trello',
        description: 'Sync reported issues and feature requests with your Trello boards.',
      },
      {
        icon: '/assets/icons/email.svg',
        title: 'Email',
        description: 'Receive email notifications for important updates and reports.',
      },
    ],
  };

  ngOnInit() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    }, 50);
  }

  ngAfterViewInit() {
    this.animateHeroSection();
    this.animateSectionTitlesAndDescriptions();
    this.animateHowItWorks();
    this.animateCards('.card');
  }

  private animateHowItWorks() {
    const steps = ['#how-it-works .how-it-works__step'];

    steps.forEach(step => {
      const textSelector = `${step} .how-it-works__text`;
      const imageSelector = `${step} .how-it-works__image`;

      gsap.from(textSelector, {
        opacity: 0,
        y: 50,
        duration: 1,
        scrollTrigger: {
          trigger: step,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });

      gsap.from(imageSelector, {
        opacity: 0,
        x: -50,
        duration: 1,
        scrollTrigger: {
          trigger: step,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });
    });
  }

  private animateHeroSection() {
    gsap.timeline()
      .from('.header__logo', { opacity: 0, y: -20, duration: 1 })
      .from('.hero__title', { opacity: 0, y: 50, duration: 1 }, '-=0.5')
      .from('.hero__description', { opacity: 0, y: 50, duration: 1 }, '-=0.5');
  }
  private animateSectionTitlesAndDescriptions() {
    const sections = ['#features', '#testimonials', '#integration'];

    sections.forEach(section => {
      const titleSelector = `${section} .features__title, ${section} .testimonials__title, ${section} .integration__title`;

      gsap.from(titleSelector, {
        opacity: 0,
        y: 30,
        duration: 1,
        scrollTrigger: {
          trigger: section,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });
    });
  }

  private animateCards(selector: string) {
    gsap.utils.toArray<HTMLElement>(selector).forEach((card, index) => {
      gsap.from(card, {
        opacity: 0,
        y: 30,
        duration: 1,
        delay: index * 0.2,
        scrollTrigger: {
          trigger: card,
          start: 'top 90%',
          toggleActions: 'play none none none',
        },
      });
    });
  }

}

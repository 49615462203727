<mat-form-field *ngIf="userId" class="no-hint-input">
  <mat-label>{{'teams.show.select_team' | translate}}</mat-label>
  <mat-select
    [(ngModel)]="selectedTeamId"
    (selectionChange)="onTeamSelected($event.value)" >
    <mat-option [value]="null">{{'teams.show.clear_filter' | translate}}</mat-option>
    <mat-option
      *ngFor="let team of userTeams"
      [value]="team.id">
      {{ team.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

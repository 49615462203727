import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Comment,
  ValidationService,
} from '../../../../core';

@Component({
  selector: 'app-create-comment',
  templateUrl: './create.component.html',
  styleUrl: 'create.component.scss',
  standalone: false,
})

export class CommentCreateComponent {
  comment: Comment;
  editCommentForm: FormGroup;

  @Input() avatar: string;

  @Input()
  set editedComment(comment: Comment) {
    this.comment = comment;
    this.editCommentForm.get('content').patchValue(comment.content);
  }

  @Input() isLoading: boolean;

  @Output() cancel = new EventEmitter<void>();

  @Output() save = new EventEmitter<string>();

  constructor(private validationService: ValidationService) {
    this.editCommentForm = new FormGroup({
      content: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }

  parseError(form: FormGroup | FormArray, formControlName: string) {
    return this.validationService.getErrorMessage(form, formControlName);
  }

  onSave() {
    this.save.emit(this.editCommentForm.get('content').value);
  }

  onCancel() {
    this.editCommentForm.reset();
    this.cancel.emit();
  }
}

import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  EMPTY,
  Observable,
  shareReplay,
  Subscription,
} from 'rxjs';
import { take } from 'rxjs/operators';

import {
  ApiService,
  AppService,
  HelperPrepareParamsService,
  LocaleService,
  RequestIds,
  SocialProvider,
  ToastsService,
  Upload,
  User,
  UserService,
  UserStatuses,
  ValidationService,
} from '../../../core';
import { ErrorsState } from '../../errors';
import { PasswordChangeDialogComponent } from '../password-change-dialog/password-change-dialog.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  standalone: false,
})
export class UserProfileComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('progressBar') progressBar: TemplateRef<any>;
  @ViewChild('passwordChangeDialog') passwordChangeDialog: TemplateRef<any>;

  loading = true;
  isSubmitting = false;
  user: User;
  form: FormGroup;
  avatarForm: FormGroup;
  uploadedAvatar: File | null | undefined;
  fileId: number;
  upload$: Observable<Upload> = EMPTY;
  maxDateOfBirth: Date;
  statuses: string[] = Object.values(UserStatuses);
  socialProvider = SocialProvider;
  sideBarStateSubscription: Subscription;
  sbOpen: boolean;
  constructor(
    private title: Title,
    private fb: FormBuilder,
    private userService: UserService,
    private validationService: ValidationService,
    private toastsService: ToastsService,
    private translateService: TranslateService,
    private appService: AppService,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private localeService: LocaleService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private store: Store
  ) {
    this.appService.title = 'pages.profile';
    this.appService.active = null;
    this.dateAdapter.setLocale(this.localeService.getCurrentLocale());
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      date_of_birth: [null],
    });
    this.avatarForm = this.fb.group(
      {
        avatar: [''],
      }
    );
    this.maxDateOfBirth = new Date();
  }

  ngOnInit() {
    this.loading = true;
    this.sbOpen = this.appService.expanded;
    this.sideBarStateSubscription = this.appService.sideBarExpandedChange.subscribe(expanded => {
      this.sbOpen = expanded;
    });
    this.userService.currentUser.pipe(take(1)).subscribe({
      next: user => {
        this.user = user;
        this.form.patchValue(this.user);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  submitForm() {
    if (!this.form.valid || this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    const params = HelperPrepareParamsService.prepareParams(this.form.value, ['date_of_birth'], true);
    this.userService.updateProfile(params).subscribe({
      next: user => {
        this.isSubmitting = false;
        this.user = user;
      },
      error: () => {
        const errors = this.store.selectSnapshot(ErrorsState.errors(RequestIds.PROFILE_UPDATE));
        for (const key in errors) {
          this.form.controls[key]?.setErrors({ invalid: true, message: errors[key] });
        }
        this.isSubmitting = false;
      },
    });
  }

  uploadAvatar(files: FileList | null): void {
    if (!files || this.isSubmitting){
      return;
    }
    const file = files.item(0);
    if (file.size > this.apiService.ALLOWED_IMAGE_SIZE) {
      this.toastsService.add(this.translateService.instant('validations.avatar.size'));
      return;
    }
    if (!this.apiService.ALLOWED_IMAGE_TYPES.includes(file.type)){
      this.toastsService.add(this.translateService.instant('validations.avatar.type'));
      return;
    }
    this.upload$ = this.apiService.upload(file).pipe(shareReplay());
    this.isSubmitting = true;
    this.openProgressBar();
    this.upload$.subscribe({
      next: fileResponse => {
        if (fileResponse.state === 'DONE') {
          this.dismissProgressBar();
          this.updateAvatar(fileResponse.data.id);
          this.isSubmitting = false;
        }
      },
      error: () => {
        this.dismissProgressBar();
        this.isSubmitting = false;
      },
    });
  }

  updateAvatar(fileId): void {
    this.isSubmitting = true;
    this.avatarForm.get('avatar').setValue({ id: fileId });
    this.userService.updateProfile(this.avatarForm.value).subscribe({
      next: user => {
        this.isSubmitting = false;
        this.user = user;
      },
      error: () => {
        this.isSubmitting = false;
      },
    });
  }

  openProgressBar(): void {
    this._snackBar.openFromTemplate(this.progressBar);
  }

  dismissProgressBar(): void {
    setTimeout(() => {
      this._snackBar.dismiss();
    }, 1000);
  }

  parseError(form: FormGroup | FormArray, formControlName: string) {
    return this.validationService.getErrorMessage(form, formControlName);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PasswordChangeDialogComponent);
    dialogRef.componentInstance.onSubmit.subscribe(
      {
        next: passwords => {
          this.changePassword(passwords, dialogRef);
        },
      }
    );
  }

  changePassword(data: any, dialogRef: MatDialogRef<any>): void {
    this.userService.changePassword(data).subscribe({
      next: () => {
        dialogRef.close();
      },
      complete: () => {
        dialogRef.componentInstance.onSubmit.unsubscribe();
      },
    });
  }

  ngOnDestroy(): void {
    this.sideBarStateSubscription.unsubscribe();
  }
}

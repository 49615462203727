import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamFilterService {
  private selectedTeamSubject = new BehaviorSubject<number | null>(null);
  selectedTeam$: Observable<number | null> = this.selectedTeamSubject.asObservable();

  setSelectedTeam(teamId: number | null): void {
    this.selectedTeamSubject.next(teamId);
  }

  getSelectedTeam(): number | null {
    return this.selectedTeamSubject.value;
  }
}

@if (isLoading) {
  <div class="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>
}
<div class="px-12 justify-content-center flex-form">
  <form [formGroup]="editTicket" (ngSubmit)="onSubmit()">
    <div class="flex-center-form flex-column">
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.title' | translate }}</mat-label>
        <input matInput formControlName="title">
        <mat-error>
          <app-validation-error-render
            label="title"
            [errors]="editTicket.controls.title.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.content' | translate }}</mat-label>
        <textarea matInput formControlName="content"></textarea>
        <mat-error>
          <app-validation-error-render
            label="content"
            [errors]="editTicket.controls.content.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

    <div formGroupName="custom_fields">
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.custom_fields.type.label' | translate }}</mat-label>
        <mat-select formControlName="type" (selectionChange)="onTypeChange($event)">
          @for (type of typeOptions; track type) {
            <mat-option [value]="type">{{ type }}</mat-option>
          }
        </mat-select>
        <mat-error>
          <app-validation-error-render
            label="customFields.type"
            [errors]="editTicket.get('custom_fields').get('type').errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      @for (field of getFilteredFields(); track field) {
        @if (customFieldOptions[field]?.length) {
          <mat-form-field appearance="fill" class="w-60">
            <mat-label>{{ getTranslation(field) }}</mat-label>
            <mat-select [formControlName]="field">
              @for (option of customFieldOptions[field] || []; track option) {
                <mat-option [value]="option">{{ option }}</mat-option>
              }
            </mat-select>
            <mat-error>
              <app-validation-error-render
                [label]="'customFields.' + field"
                [errors]="editTicket.get('custom_fields').get(field).errors">
              </app-validation-error-render>
            </mat-error>
          </mat-form-field>
        }
      }
    </div>
    <mat-form-field appearance="fill" class="w-60">
      <mat-label>{{ 'tickets.form.page_url' | translate }}</mat-label>
      <input matInput formControlName="page_url">
      <mat-error>
        <app-validation-error-render
          label="page_url"
          [errors]="editTicket.controls.page_url.errors">
        </app-validation-error-render>
      </mat-error>
    </mat-form-field>
  </div>
    <button mat-raised-button color="primary" type="submit" [disabled]="!editTicket.valid || isLoading">
     {{ 'actions.submit' | translate }}
    </button>
  </form>
</div>

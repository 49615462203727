import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AbilityBuilder } from '@casl/ability';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  skipWhile,
  take,
} from 'rxjs/operators';

import { AppAbility } from './ability.service';
import { ApiService } from './api.service';
import { AppService } from './app.service';
import { JwtService } from './jwt.service';
import { ToastsService } from './toasts.service';

import { REQUEST_ID } from '../../shared';
import {
  BaseResponse,
  RequestIds,
  Token,
  User,
  UserRoles,
} from '../models';

@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  public storedUser: User | null = null;
  public isAuthenticatedSubject = new BehaviorSubject<boolean>(true);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  public populatePromise: Promise<User|null>;

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private toastsService: ToastsService,
    private router: Router,
    private ability: AppAbility,
    private translateService: TranslateService,
  ) {
    this.currentUser.subscribe(user => {
      this.storedUser = user;
    });
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate(): Promise<User|null> {
    this.populatePromise = new Promise((resolve, reject) => {
      // If JWT detected, attempt to get & store user's info
      if (this.jwtService.getAccessToken()) {
        this.apiService.get<User>('/auth/profile').subscribe({
          next: response => {
            this.currentUserSubject.next(response.data);
            this.updateUserPermissions(response.data);
            resolve(response.data as User);
          },
          error: () => {
            this.purgeAuth();
            resolve(null);
          },
        });
      } else {
        // Remove any potential remnants of previous auth states
        this.purgeAuth();
        resolve(null);
      }
    });
    return this.populatePromise;
  }

  setAuth(token: Token) {
    // Save JWT sent from server in localstorage
    if (!token) {
      this.isAuthenticatedSubject.next(false);
    }
    if (token.access_token) {
      this.jwtService.setAccessToken(token.access_token);
    }
    if (token.refresh_token) {
      this.jwtService.setRefreshToken(token.refresh_token);
    }
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  updateUserPermissions(user: User) {
    const { can, rules } = new AbilityBuilder(AppAbility);
    if (user.role === UserRoles.USER) {
      can('read', 'User');
    } else if (user.role === UserRoles.ADMIN) {
      can('create', 'User');
      can('update', 'User');
      can('read', 'User');
      can('delete', 'User');
    }
    this.ability.update(rules);
  }

  purgeAuth() {
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    if (this.jwtService.getAccessToken()) {
      this.apiService.post('/auth/logout');
    }
    // Remove JWT from localstorage
    this.jwtService.destroyAccessToken();
    this.jwtService.destroyRefreshToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    this.ability.update([]);
  }

  attemptAuth(params: object) {
    const context = new HttpContext().set(REQUEST_ID, RequestIds.USER_LOGIN);

    params['app'] = AppService.appType;
    return this.apiService.post<Token>('/auth/login', params, false, context)
      .pipe(map(
        response => {
          this.setAuth(response.data);
          return response.data;
        }
      ));
  }

  register(params: object) {
    const context = new HttpContext().set(REQUEST_ID, RequestIds.USER_REGISTER);

    return this.apiService.post<[]>('/auth/register', params, false, context)
      .pipe(map(
        response => {
          if (response.data) {
            this.toastsService.add(this.translateService.instant('messages.registeredSuccessfully'));
            return response.data;
          }
        }
      ));
  }

  openHomePage() {
    if (this.router.url === '/') {
      this.isAuthenticated.pipe(take(1)).subscribe({
        next: value => {
          if (!value) {
            this.router.navigateByUrl('/login');
          }
        },
      });
    }
    this.currentUser.pipe(
      skipWhile((user) => !user.hasOwnProperty('role')), take(1)
    ).subscribe(
      user => {
        let home = '/dashboard';
        if (!user.onboarded_at) {
          home = '/onboarding';
        }
        this.router.navigateByUrl(home);
      }
    );
  }

  logout() {
    this.purgeAuth();
    this.router.navigateByUrl('/login');
    window.location.reload();
  }

  refreshToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.jwtService.refreshToken().subscribe({
        next: () => {
          resolve(true);
        },
        error: () => {
          this.logout();
          resolve(false);
        },
      });
    });
  }

  sendConfirmation(email: string) {
    return this.apiService.post('/auth/send-confirm-email', { email }) as Observable<BaseResponse<any>>;
  }

  confirmEmail(params: object, withPassword: boolean = false): Observable<any> {
    return this.apiService.post(withPassword ? '/auth/confirm-password' : '/auth/confirm', params)
      .pipe(map(
        data => {
          this.toastsService.add(this.translateService.instant('messages.emailConfirmed'));
          return data;
        }
      ));
  }

  forgotPassword(params: object): Observable<any> {
    return this.apiService.post('/auth/reset-password-init', params)
      .pipe(map(
        data => {
          this.toastsService.add(this.translateService.instant('messages.resetPasswordLintSent'));
          return data;
        }
      ));
  }

  renewPassword(params: object): Observable<any> {
    return this.apiService.post('/auth/reset-password', params);
  }

  changePassword(params: object): Observable<any> {
    return this.apiService.post('/auth/change-password', params);
  }

  updateProfile(params: object, upload = false): Observable<User> {
    const context = new HttpContext().set(REQUEST_ID, RequestIds.PROFILE_UPDATE);

    return this.apiService.patch<User>('/auth/profile', params, upload, context)
      .pipe(map(
        response => {
          if (response.data) {
            this.currentUserSubject.next(response.data);
            this.updateUserPermissions(response.data);
            return response.data;
          }
        }
      ));
  }

  profile() {
    return this.apiService.get<User>('/auth/profile')
      .pipe(map(
        response => {
          this.currentUserSubject.next(response.data);
          this.updateUserPermissions(response.data);
          return response.data;
        }
      ));
  }

  samlCallback(params: object) {
    return this.apiService.post<Token>('/auth/saml-callback', params)
      .pipe(map(
        response => {
          this.setAuth(response.data);
          return response.data;
        }
      ));
  }
}

import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  ProjectMember,
  ProjectsService,
  TeamMember,
  TeamMemberService,
  ToastsService,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import { DataTableComponent } from '../data-table/data-table.component';

@Component({
  selector: 'app-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss'],
  standalone: false,
})
export class ProjectMembersComponent extends DataTableComponent implements AfterViewInit {

  loading = false;
  displayedColumns: Array<string> = ['id', 'user_id', 'role', 'name', 'actions'];
  datasource: AjaxDataSource<ProjectMember>;
  defaultFilters: { q: string } = { q: '' };
  filterService: string = 'projectsService';
  filterStorage: string = '_filterProjectMembers';
  members: ProjectMember[];
  teamMembers: TeamMember[] = [];
  projectTeamid: number;

  @Input() projectId: number;

  @Input() set teamId(teamId: number) {
    if (teamId) {
      this.projectTeamid = teamId;
      this.loadTeam();
    }
  }

  @ViewChild('addUserSelect') addUserSelect: MatSelect;

  constructor(
    private appService: AppService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private toastsService: ToastsService,
    private projectsService: ProjectsService,
    private teamMemberService: TeamMemberService
  ) {
    super();
    this.appService.title = 'pages.projects.list';
    this.appService.active = 'projects';
    this.datasource = new AjaxDataSource<ProjectMember>();
    this.members = [];
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.loadMembers();
  }

  loadMembers(): void {
    this.projectsService.getProjectMembers(this.projectId)
      .subscribe(data => {
        this.members = data.list;
      });
  }

  loadTeam(): void {
    if (this.projectTeamid) {
      this.teamMemberService.fetchAllowedToAssignTeamMembers(this.projectTeamid, this.projectId).subscribe((data) => {
        this.teamMembers = [...data.list];
      });
    }
  }

  reload(): void {
    this.updateDataSource(this.projectsService.getProjectMembers(this.projectId, this.ajaxParams.getHttpParams()));
    this.loadTeam();
  }

  onAddUser() {
    if (this.addUserSelect.value) {
      this.projectsService.assignTeamMember(this.projectId, this.addUserSelect.value)
        .subscribe({
          next: () => {
            this.teamMembers = this.teamMembers.filter((member) => member.id !== this.addUserSelect.value);
            this.reload();
            this.toastsService.add('TeamMember successfully added to project');
          },
          error: () => this.toastsService.add('Something went wrong. Please try again'),
        });
    }
  }

  unassignTeamMember(id: number) {
    this.projectsService.unAssignTeamMember(this.projectId, id)
      .subscribe({
        next: () => {
          this.reload();
          this.toastsService.add('TeamMember successfully unassigned');
        },
        error: () => this.toastsService.add('Something went wrong. Please try again'),
      });
  }
}

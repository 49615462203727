import {
  Component,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  ToastsService,
  TrelloConfig,
  TrelloConfigsService,
  User,
} from '../../../../../../core';
import { AjaxDataSource } from '../../../../../../core/services/ajax.datasource';
import { ConfirmDialogComponent } from '../../../../../components';
@Component({
  selector: 'app-trello-config-view',
  templateUrl: './view.component.html',
  styleUrl: './view.component.scss',
  standalone: false,
})
export class TrelloConfigViewComponent implements OnInit{

  datasource: AjaxDataSource<TrelloConfig>;
  trelloConfigId: number | undefined;
  teamId: number | undefined;
  trelloConfig: TrelloConfig;
  user: User;
  loading = false;
  displayedColumns: Array<string> = ['id', 'name', 'custom_fields', 'action'];
  defaultFilters: {} = {};
  filterService: string = 'trelloConfig';
  filterStorage: string = '_filterAdminTrelloConfigView';
  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private trelloConfigService: TrelloConfigsService,
    private  toastsService: ToastsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.appService.title = 'teams.integrations.trello_config';
    this.appService.active = 'trelloConfig';
    this.datasource = new AjaxDataSource<TrelloConfig>();
  }

  ngOnInit(): void {
    this.trelloConfigId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.teamId = parseInt(this.route.snapshot.paramMap.get('teamId'));
    if (this.trelloConfigId) {
      this.trelloConfigService.getTrelloConfig(this.trelloConfigId).subscribe({
        next: (data) => {
          this.trelloConfig = data;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.translateService.get('teams.integrations.config_load').subscribe((translatedMessage) => {
            this.toastsService.add(translatedMessage);
          });
        },
      });
    } else {
      this.loading = false;
    }
  }

  openDeleteDialog(): void {
    const dialogData = {
      title: this.translateService.instant('teams.integrations.deleteConfig.title'),
      message: this.translateService.instant('teams.integrations.deleteConfig.message'),
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteTrelloConfig();
      }
    });
  }

  deleteTrelloConfig(): void {
    if (this.trelloConfigId) {
      this.trelloConfigService.deleteTrelloConfig(this.trelloConfigId).subscribe({
        next: () => {
          this.router.navigate([`/teams/${this.teamId}/integrations/trello/`]);
          this.snackBar.open(
            this.translateService.instant('teams.integrations.deleteConfig.success'),
            this.translateService.instant('teams.integrations.deleteConfig.close'),
            { duration: 3000 }
          );
        },
        error: () => {
          this.snackBar.open(
            this.translateService.instant('teams.integrations.deleteConfig.error'),
            this.translateService.instant('teams.integrations.deleteConfig.close'),
            { duration: 3000 }
          );
        },
      });
    }
  }
}

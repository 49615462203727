import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: false,
})
export class CardComponent {
  @Input() title!: string;
  @Input() description!: string;
  @Input() image!: string;
  @Input() isReverse!: boolean;
  @Input() isH3: boolean = true;

}


import {
  AfterViewInit,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  AppService,
  Team,
  TeamsService,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import { DataTableComponent } from '../../components';
import { LayoutService } from '../../services';

@Component({
  selector: 'app-teams-listing',
  templateUrl: './teams.component.html',
  styleUrl: './teams.component.scss',
  standalone: false,
})
export class TeamsComponent extends DataTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild('headerButtons', { static: true }) headerButtons!: TemplateRef<any>;

  loading = false;
  displayedColumns: Array<string> = ['name', 'url', 'action'];
  datasource: AjaxDataSource<Team>;
  defaultFilters: { q: string } = { q: '' };
  filterService: string = 'teamsService';
  filterStorage: string = '_filterAdminTeams';
  teams: Array<{ id: number; name: string }> = [];

  constructor(
    private appService: AppService,
    private teamsService: TeamsService,
    private layoutService: LayoutService,
  ) {
    super();
    this.appService.title = 'pages.teams.list';
    this.appService.active = 'teams';
    this.datasource = new AjaxDataSource<Team>();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.layoutService.setHeaderButtons(this.headerButtons);
  }

  ngOnDestroy(): void {
    this.layoutService.setHeaderButtons(null);
  }

  reload() {
    const service = this.teamsService.fetchTeams(this.ajaxParams.getHttpParams());
    this.updateDataSource(service);
    service.subscribe(data => {
      this.teams = data.list.map(item => ({
        id: item.id,
        name: item.name,
      }));
    });
  }
}

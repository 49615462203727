<div class="card">
  <img *ngIf="image" [src]="image" [alt]="title" class="card__image">
  <div class="card__content" [class.card__content-reverse]="isReverse">
    <h3 *ngIf="isH3; else h4Template" class="card__title">{{ title }}</h3>
    <ng-template #h4Template>
      <h4 class="card__title">{{ title }}</h4>
    </ng-template>
    <p class="card__description">{{ description }}</p>
  </div>
</div>

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { AppService } from '../../../core';

export interface MenuRow {
  id: string,
  title: string,
  link: string,
  icon: string,
  children?: MenuRow[],
  expanded?: boolean;
  isHidden?: boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: false,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() menu: MenuRow[];
  active: string;
  expanded: boolean;

  activePageSubscription: Subscription;
  sideBarStateSubscription: Subscription;

  constructor(private appService: AppService) {
    this.activePageSubscription = this.appService.activePageChange.subscribe({
      next: value => this.active = value,
    });
    this.sideBarStateSubscription = this.appService.sideBarExpandedChange.subscribe(expanded => {
      this.expanded = expanded;
    });
  }

  ngOnInit(): void {
    this.expanded = this.appService.expanded;
    this.prepareMenu();
  }

  ngOnDestroy(): void {
    this.activePageSubscription.unsubscribe();
    this.sideBarStateSubscription.unsubscribe();
  }

  toggle() {
    this.expanded = !this.expanded;
    this.appService.expanded = this.expanded;
  }

  prepareMenu(): void {
    for (let item of this.menu) {
      if (item.children) {
        for (let childrenItem of item.children) {
          item.expanded = this.active === childrenItem.id;
        }
        item.isHidden = true;
      }
    }
  }

  beforeSwitch(): void {
    if (this.appService.isMobile) {
      this.toggle();
    }
  }
}

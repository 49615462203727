import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import {
  AppService,
  TicketConfig,
  TicketConfigsService,
} from '../../../../../core';
import { AjaxDataSource } from '../../../../../core/services/ajax.datasource';
import { DataTableComponent } from '../../../../components';

@Component({
  selector: 'app-ticket-config-listing',
  templateUrl: './ticket.component.html',
  styleUrl: './ticket.component.scss',
  standalone: false,
})
export class TicketConfigComponent extends DataTableComponent implements AfterViewInit, OnInit {
  loading = false;
  displayedColumns: Array<string> = ['id','name', 'action'];
  teamId: number | undefined;
  datasource: AjaxDataSource<TicketConfig>;
  defaultFilters: {q: string} = { q: '' };
  filterService: string = 'trelloConfigService';
  filterStorage: string = '_filterTrelloConfig';

  constructor(
    private appService: AppService,
    private ticketConfigService: TicketConfigsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.appService.title = 'teams.integrations.ticket_config';
    this.appService.active = 'trelloConfig';
    this.datasource = new AjaxDataSource<TicketConfig>();
  }

  ngOnInit(): void {
    this.teamId = parseInt(this.route.snapshot.paramMap.get('id'));
  }

  navigateToTicketCreate(): void {
    this.router.navigateByUrl(`/teams/${this.teamId}/integrations/ticket/create`);
  }

  navigateToTicket(id: number): void {
    this.router.navigateByUrl(`/teams/${this.teamId}/integrations/ticket/${id}`);
  }

  reload() {
    this.updateDataSource(this.ticketConfigService.fetchTicketConfigs(this.ajaxParams.getHttpParams()));
  }
}

import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-validation-error-render',
  templateUrl: './validation-error-render.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ValidationErrorRenderComponent),
  }],
  standalone: false,
})
export class ValidationErrorRenderComponent implements OnChanges {
  @Input() errors: any = null;
  @Input() label?: string;

  errorsList: Set<string>;

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.errors) {
      return;
    }
    this.errorsList = new Set<string>;
    if (this.errors?.message && Array.isArray(this.errors?.message)) {
      this.errorsList.add(this.errors.message);
      return;
    }
    if (this.errors) {
      Object.keys(this.errors)?.forEach(key => this.addMessage(key));
    }
  }

  async addMessage(value) {
    if (!this.label) {
      return value;
    }
    const translateKey = 'form.' + this.label + '.errors.' + value;
    this.translateService.get(translateKey).subscribe(translate => {
      this.errorsList.add(translate !== translateKey ? translate : value);
    });
  }
}

import { HttpParams } from '@angular/common/http';
import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

import {
  AppService,
  HelperPrepareParamsService,
  Project,
  ProjectsService,
  SlackConfig,
  SlackConfigsService,
  TicketConfig,
  TicketConfigsService,
  ToastsService,
  TrelloConfig,
  TrelloConfigsService,
  User,
} from '../../../../core';

@Component({
  selector: 'app-admin-project-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss',
  standalone: false,
})
export class ProjectEditComponent implements OnInit {
  editProject: FormGroup;
  user: User;
  defaultRoleName: string;

  ticketConfigs: TicketConfig[] = [];
  trelloConfigs: TrelloConfig[] = [];
  slackConfigs: SlackConfig[] = [];
  selectedTicketConfigId: number | null = null;

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private router: Router,
    private projectsService: ProjectsService,
    private slackConfigService: SlackConfigsService,
    private trelloConfigsService: TrelloConfigsService,
    private ticketConfigsService: TicketConfigsService,
    private translate: TranslateService,
    private toastsService: ToastsService,
  ) {
  }

  ngOnInit() {
    this.appService.title = 'projects.edit.title';
    const projectId = +this.route.snapshot.paramMap.get('id');
    const params = { 'with_permissions': 'true' };

    this.initializeForm();

    forkJoin({
      ticketConfigs: this.ticketConfigsService.fetchTicketConfigs(),
      project: this.projectsService.getProjectWithParams(projectId, params),
    }).subscribe({
      next: (result) => {
        this.ticketConfigs = result.ticketConfigs.list;

        this.populateForm(result.project);
        if (result.project.ticket_config_id) {
          this.selectedTicketConfigId = result.project.ticket_config_id;
          this.loadRelatedConfigs(result.project.ticket_config_id);
        }
      },
      error: (error) => {
        this.toastsService.add(error);
      },
    });

    this.translate.get('form.defaultRoleName').subscribe(translatedRoleName => {
      this.defaultRoleName = translatedRoleName;
      this.addDefaultRole();
    });
  }

  initializeForm() {
    this.editProject = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      key: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      ticket_config_id: [null, [Validators.required, Validators.pattern('^[0-9]+$')]],
      trello_config_id: [{ value: null, disabled: true }, [Validators.required, Validators.pattern('^[0-9]+$')]],
      slack_config_id: [{ value: null, disabled: true }, [Validators.required, Validators.pattern('^[0-9]+$')]],
      roles: this.fb.array([]),
    });
  }

  populateForm(project: Project) {
    this.editProject.patchValue({
      name: project.name,
      key: project.key,
      ticket_config_id: project.ticket_config_id,
      trello_config_id: project.trello_config_id,
      slack_config_id: project.slack_config_id,
    });

    this.roles.clear();

    const permissions = JSON.parse(project.permissions || '{}');
    Object.entries(permissions).forEach(([roleName, access], index) => {
      const isFirstRole = index === 0;
      this.roles.push(this.fb.group({
        name: [{ value: roleName, disabled: isFirstRole }],
        access: [access],
      }));
    });
  }

  loadRelatedConfigs(ticketConfigId: number) {
    if (!ticketConfigId) return;

    this.selectedTicketConfigId = ticketConfigId;

    // Enable the related config controls
    this.editProject.get('trello_config_id').enable();
    this.editProject.get('slack_config_id').enable();

    const filters = new HttpParams()
      .set('team_id', this.ticketConfigs.find(config => config.id === this.selectedTicketConfigId).team_id)
      .set('ticket_config_id', ticketConfigId)
      .set('per_page', 50);

    forkJoin({
      trello: this.trelloConfigsService.fetchTrelloConfigs(filters),
      slack: this.slackConfigService.fetchSlackConfigs(filters),
    }).subscribe({
      next: (result) => {
        this.trelloConfigs = result.trello.list;
        this.slackConfigs = result.slack.list;
      },
      error: (error) => {
        this.toastsService.add(error);
      },
    });
  }

  onTicketConfigChange(event: MatSelectChange) {
    const ticketConfigId = event.value;

    // Reset dependent selects
    this.editProject.patchValue({
      trello_config_id: null,
      slack_config_id: null,
    });

    if (ticketConfigId) {
      this.loadRelatedConfigs(ticketConfigId);
    } else {
      this.selectedTicketConfigId = null;
      this.editProject.get('trello_config_id').disable();
      this.editProject.get('slack_config_id').disable();
      this.trelloConfigs = [];
      this.slackConfigs = [];
    }
  }

  get roles(): FormArray {
    return this.editProject.get('roles') as FormArray;
  }

  addDefaultRole() {
    this.roles.insert(0, this.fb.group({
      name: [{ value: this.defaultRoleName, disabled: true }],
      access: ['accessOwn'],
    }));
  }

  addRole() {
    this.roles.push(this.fb.group({
      name: ['', Validators.required],
      access: ['accessOwn'],
    }));
  }

  removeRole(index: number) {
    if (this.roles.length > 1) {
      this.roles.removeAt(index);
    }
  }

  onSubmit() {
    if (this.editProject.valid) {
      const projectId = +this.route.snapshot.paramMap.get('id');
      const formValue = this.editProject.getRawValue();

      const permissions = formValue.roles.reduce((
        acc: { [roleName: string]: string },
        role: { name: string; access: string }) => {
        if (role.name) {
          acc[role.name] = role.access;
        }
        return acc;
      }, {});

      const { roles, ...rest } = formValue;
      const projectEditParams = {
        ...HelperPrepareParamsService.prepareParams(rest),
        permissions: permissions,
      };

      this.projectsService.updateProject(projectId, projectEditParams).subscribe({
        next: project => {
          this.router.navigateByUrl('/projects/' + project.id);
        },
      });
    }
  }
}

import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  Team,
  TeamsService,
  TicketConfigsService,
  ToastsService,
} from '../../../../../../core';
@Component({
  selector: 'app-integrations-ticket',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  standalone: false,
})
export class TicketConfigCreateComponent implements OnInit, AfterContentChecked {
  @ViewChild('progressBar') progressBar: TemplateRef<any>;
  @ViewChild('customFieldDialog') customFieldDialog: TemplateRef<any>;
  team: Team;
  teamId: number;
  ticketConfigForm: FormGroup;
  isSubmitting: boolean = false;
  loading = true;
  newFieldName: string = '';
  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private ticketConfigService: TicketConfigsService,
    private teamsService: TeamsService,
    private toastsService: ToastsService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {
    this.initializeForm();
    this.appService.title = 'pages.teams.integrations';
    this.appService.active = 'teams';
  }
  initializeForm(): void {
    this.ticketConfigForm = this.fb.group({
      done_status: ['Done', Validators.required],
      name: ['', [Validators.required]],
      custom_fields: this.fb.group({
        type: this.fb.array(['Bug', 'Feature Request', 'Tweak'], Validators.required),
        status: this.fb.array(['New', 'In Progress', 'Done'], Validators.required),
        level: this.fb.array([1, 2, 3], Validators.required),
        priority: this.fb.array(['Low', 'Medium', 'High'], Validators.required),
      }),
      required_fields: this.fb.group({
        Bug: this.fb.array(['status', 'level', 'priority']),
        'Feature Request': this.fb.array(['status', 'priority']),
        Tweak: this.fb.array(['status', 'priority']),
      }),
      team_id: [null, Validators.required],
    });
  }
  ngOnInit(): void {
    this.teamId = +this.route.snapshot.paramMap.get('id');
    if (this.teamId) {
      this.ticketConfigForm.patchValue({ team_id: this.teamId });
      this.loadTeamDetails();
    }
  }
  loadTeamDetails(): void {
    this.teamsService.getTeam(this.teamId).subscribe({
      next: (team: Team) => {
        this.team = team;
        this.appService.title = `Integrations for ${this.team.name}`;
        this.loading = false;
      },
      error: () => this.router.navigateByUrl(`/teams/${this.teamId}`),
    });
  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  get requiredFields(): FormGroup {
    return this.ticketConfigForm.get('required_fields') as FormGroup;
  }
  getTypeRequiredFields(type: string): FormArray {
    if (!this.requiredFields.contains(type) && type !== 'type') {
      this.requiredFields.addControl(type, this.fb.array([]));
    }
    return this.requiredFields.get(type)  as FormArray;
  }
  onRequiredFieldsChange(type: string, selectedValues: string[]): void {
    const formArray = this.getTypeRequiredFields(type);
    formArray.clear();
    selectedValues.forEach(value => formArray.push(this.fb.control(value)));
  }
  get customFields(): FormGroup {
    return this.ticketConfigForm.get('custom_fields') as FormGroup;
  }
  get typeArray(): FormArray {
    return this.customFields.get('type') as FormArray;
  }
  addCustomField(): void {
    if (this.newFieldName.trim()) {
      const fieldArray = this.getFieldArray(this.newFieldName);
      if (!fieldArray) {
        this.customFields.addControl(this.newFieldName, this.fb.array([]));
      }
      this.getFieldArray(this.newFieldName).push(this.fb.control('', Validators.required));
      this.newFieldName = '';
    }
  }
  onTypeChange(index: number, event: Event): void {
    const target = event.target as HTMLInputElement;
    const newValue = target.value;
    const typeArray = this.typeArray;
    const oldValue = typeArray.at(index).value;
    if (oldValue === newValue) return;
    if (this.requiredFields.contains(oldValue)) {
      const existingValues = (this.requiredFields.get(oldValue) as FormArray).value;
      this.requiredFields.removeControl(oldValue);
      this.requiredFields.addControl(newValue, this.fb.array(existingValues));
      delete this.requiredFields.value[oldValue];
    }
    typeArray.at(index).setValue(newValue);
  }
  openCustomFieldModal(): void {
    const dialogRef = this.dialog.open(this.customFieldDialog, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.addCustomField();
    });
  }
  getTranslation(field: string): string {
    const translated = this.translateService.instant('teams.integrations.' + field);
    const result = translated === 'teams.integrations.' + field ? field : translated;
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  getCustomFieldKeys() {
    const keys = Object.keys(this.customFields.controls);
    return keys.filter(key => key !== 'type');
  }
  getKeys(){
    return Object.keys(this.customFields.controls);
  }
  getFieldArray(field: string): FormArray {
    return this.customFields.get(field) as FormArray;
  }
  addField(field: string): void {
    this.getFieldArray(field).push(this.fb.control('', Validators.required));
  }
  removeField(field: string, index: number): void {
    const typeArray = this.typeArray;
    const fieldArray = this.getFieldArray(field);

    const fieldValue = fieldArray.at(index).value;

    typeArray.removeAt(index);

    if (this.requiredFields.contains(fieldValue)) {
      const requiredFieldArray = this.requiredFields.get(fieldValue) as FormArray;
      requiredFieldArray.clear();
      this.requiredFields.removeControl(fieldValue);
    }

    fieldArray.removeAt(index);
  }

  onSubmit(): void {
    if (this.ticketConfigForm.valid) {
      this.isSubmitting = true;
      this.ticketConfigService.createTicketConfig(this.ticketConfigForm.value).subscribe({
        next: (ticket) => {
          this.isSubmitting = false;
          const id = ticket.id;
          this.router.navigate([`/teams/${this.teamId}/integrations/ticket/${id}`]);
        },
        error: () => {
          this.isSubmitting = false;
          this.toastsService.add('Failed to save configuration');
        },
      });
    }
  }
}

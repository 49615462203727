export interface TicketConfig {
  id: number;
  done_status: string;
  custom_fields: {
    type: string[];
    status: string[];
    level: number[];
    priority: string[];
  },
  created_at?: Date;
  updated_at?: Date;
  team_id: number;
  name: string;
  required_fields: {string: string[]};
}

export const TicketDefaultKey: string = 'default';

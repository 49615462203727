import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  Observable,
  shareReplay,
} from 'rxjs';

import {
  ApiService,
  AppService,
  HelperPrepareParamsService,
  ProjectsService,
  TicketConfigsService,
  TicketsService,
  ToastsService,
  Upload,
} from '../../../../core';

@Component({
  selector: 'app-ticket-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
  standalone: false,
})
export class TicketCreateComponent implements OnInit {
  isLoading = false;
  createTicket: FormGroup;
  upload$: Observable<Upload> = EMPTY;
  uploadedFileIds: string[] = [];
  required_fields: { [key: string]: string[] } = {};
  typeOptions = [];
  customFieldKeys: string[] = [];
  customFieldOptions: { [key: string]: string[] } = {};
  selectedType='';
  selectedTicketConfigId?: number = null;
  preview: any;
  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private router: Router,
    private ticketsService: TicketsService,
    private ticketConfigService: TicketConfigsService,
    private projectsService: ProjectsService,
    private apiService: ApiService,
    private toastsService: ToastsService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.appService.title = 'tickets.create.title';
    this.createTicket = this.fb.group({
      source: ['', [Validators.required]],
      title: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      content: ['', [Validators.required, Validators.minLength(20)]],
      custom_fields: this.fb.group({
        type: [''],
      }),
      page_url: ['', [
        Validators.required,
        Validators.pattern(/https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&=]*)/),
      ]],
      files: [[]],
    });

    this.route.paramMap.subscribe(params => {
      const projectId = params.get('projectId') || '';
      this.createTicket.get('source').setValue(projectId);
      this.loadProjectAndConfigs(projectId);
    });
  }
  onTypeChange(event: any) {
    this.selectedType = event.value;
    this.getFilteredFields();

    const customFieldsGroup = this.createTicket.get('custom_fields') as FormGroup;

    Object.keys(customFieldsGroup.controls).forEach(key => {
      if (key !== 'type') {
        customFieldsGroup.removeControl(key);
      }
    });

    const requiredFieldsForType = this.required_fields?.[this.selectedType] || [];
    requiredFieldsForType.forEach(field => {
      customFieldsGroup.addControl(field, this.fb.control('', Validators.required));
    });

    customFieldsGroup.get('type')?.setValue(this.selectedType);
  }

  loadProjectAndConfigs(projectId: string) {
    if (projectId) {
      this.projectsService.getProject(projectId).subscribe({
        next: (project) => {
          this.selectedTicketConfigId = project.ticket_config_id;
          this.loadTicketConfigs();
        },
      });
    }
  }

  loadTicketConfigs() {
    this.ticketConfigService.getTicketConfig(this.selectedTicketConfigId).subscribe({
      next: (config) => {
        if (config && config.custom_fields) {
          this.typeOptions = config.custom_fields.type;
          this.customFieldKeys = Object.keys(config.custom_fields);
          this.customFieldOptions = this.convertOptionsToString(config.custom_fields);
          this.required_fields = config.required_fields;

          const customFieldsGroup = this.fb.group({});

          this.customFieldKeys.forEach(field => {
            customFieldsGroup.addControl(field, this.fb.control('',Validators['required']));
          });

          this.createTicket.setControl('custom_fields', customFieldsGroup);

        }
      },
    });
  }
  getFilteredFields() {
    if (!this.required_fields || !this.selectedType) {
      return [];
    }

    const fieldsForSelectedType = this.required_fields[this.selectedType] || [];
    return [...fieldsForSelectedType];
  }

  convertOptionsToString(customFields: { [key: string]: (string[] | number[]) }): { [key: string]: string[] } {
    const result: { [key: string]: string[] } = {};
    for (const key in customFields) {
      if (Array.isArray(customFields[key])) {
        result[key] = customFields[key].map((option: string | number) => option.toString());
      }
    }
    return result;
  }

  uploadAvatar(files: FileList | null): void {
    if (!files || files.length === 0) {
      this.toastsService.add(this.translateService.instant('validations.avatar.required'));
      return;
    }
    const file = files.item(0);
    if (!file) {
      return;
    }
    if (file.size > this.apiService.ALLOWED_IMAGE_SIZE) {
      this.toastsService.add(this.translateService.instant('validations.avatar.size'));
      return;
    }
    if (!this.apiService.ALLOWED_IMAGE_TYPES.includes(file.type)) {
      this.toastsService.add(this.translateService.instant('validations.avatar.type'));
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      this.preview = e.target?.result;
    };
    reader.readAsDataURL(file);
    this.sendAvatar(file);
  }

  sendAvatar(file: File): void {
    if (!file) {
      return;
    }

    this.upload$ = this.apiService.upload(file).pipe(shareReplay());

    this.upload$.subscribe({
      next: (fileResponse) => {
        if (fileResponse.state === 'DONE' && fileResponse.data?.id) {
          this.uploadedFileIds.push(fileResponse.data.id);
          this.toastsService.add(this.translateService.instant('File uploaded successfully'));
        }
      },
      error: (err) => {
        this.toastsService.add(this.translateService.instant('File upload failed. Please try again.'));
      },
    });
  }

  removeAvatar(): void {
    this.preview = null;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const target = event.currentTarget as HTMLElement;
    target.classList.add('drag-over');
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const target = event.currentTarget as HTMLElement;
    target.classList.remove('drag-over');

    if (event.dataTransfer?.files.length) {
      this.uploadAvatar(event.dataTransfer.files);
    }
  }
  getTranslation(field: string): string {
    const key = `tickets.form.custom_fields.${field}.label`;
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : field;
  }

  onSubmit(): void {
    if (!this.createTicket.valid) {
      return;
    }

    this.isLoading = true;
    let ticketCreateParams = HelperPrepareParamsService.prepareParams(this.createTicket.value);

    if (this.required_fields && this.selectedType) {
      const requiredFieldsForType = this.required_fields[this.selectedType] || [];
      const customFields = this.createTicket.value.custom_fields || {};

      const filteredCustomFields: { [key: string]: any } = { type: this.selectedType };
      requiredFieldsForType.forEach(field => {
        if (customFields[field] !== undefined) {
          filteredCustomFields[field] = customFields[field];
        }
      });

      ticketCreateParams.custom_fields = filteredCustomFields;
    }

    if (this.uploadedFileIds.length > 0) {
      ticketCreateParams.files = this.uploadedFileIds.map(id => ({ id }));
    }

    this.ticketsService.createTicket(ticketCreateParams).subscribe({
      next: ticket => {
        this.router.navigateByUrl('/tickets/' + ticket.id);
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}

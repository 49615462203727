import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';

import {
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  map,
  take,
} from 'rxjs/operators';

import {
  Project,
  ProjectsService,
  ToastsService,
} from '../../core';

@Injectable()
export class ProjectExistsGuard implements CanActivate {
  constructor(
    private router: Router,
    private projectsService: ProjectsService,
    private toastsService: ToastsService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const projectId = route.paramMap.get('id');

    if (!projectId) {
      this.redirectWithError();
      return of(false);
    }

    return this.projectsService.getProject(projectId).pipe(
      take(1),
      map((project: Project) => {
        if (!project) {
          this.redirectWithError();
          return false;
        }
        return true;
      }),
      catchError(() => {
        this.redirectWithError();
        return of(false);
      })
    );
  }

  redirectWithError() {
    this.router.navigateByUrl('/projects');
    this.toastsService.add('Project not exists.');
  }
}

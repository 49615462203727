import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import {
  AppService,
  HelperPrepareParamsService,
  TeamsService,
  User,
  UserService,
  ValidationService,
} from '../../../../core';

@Component({
  selector: 'app-team-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
  standalone: false,
})
export class TeamCreateComponent implements OnInit {
  teamForm: FormGroup;
  user: User;

  constructor(
    private appService: AppService,
    private fb: FormBuilder,
    private teamsService: TeamsService,
    private router: Router,
    private userService: UserService,
    private validationService: ValidationService
  ) {}

  ngOnInit() {
    this.appService.title = 'teams.create.title';
    this.teamForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(6)]],
      url: ['', [Validators.pattern('https?://.+')]],
    });
    this.userService.currentUser.subscribe((user) => {
      this.user = user;
    });
  }

  onSubmit() {
    if (this.teamForm.valid) {
      const teamCreateParams = HelperPrepareParamsService.prepareParams(this.teamForm.value);
      this.teamsService.createTeam(teamCreateParams).subscribe({
        next: (team) => {
          this.router.navigateByUrl('/teams/' + team.id);
        },
        error: (err) => {
          Object.keys(err.data).forEach((propertyName) => {
            this.teamForm
              .get(propertyName)
              .setErrors({ serverError: { message: err.data[propertyName] } });
          });
        },
      });
    }
  }

  parseError(form: FormGroup, formControlName: string) {
    return this.validationService.getErrorMessage(form, formControlName);
  }
}

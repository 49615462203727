import { HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import {
  PaginationContent,
  Project,
  ProjectsService,
} from 'src/modules/core';

@Component({
  selector: 'app-project-selection-dialog',
  templateUrl: 'project-selection-dialog.component.html',
  standalone: false,
})
export class ProjectSelectionDialogComponent implements OnInit, OnDestroy {
  /** Control for the selected project */
  @Input() projectCtrl: FormControl = new FormControl<Project>(null, Validators.required);

  /** Label for the select */
  @Input() label: string = 'Project';

  /** Output the selected project object when it changes */
  @Output() projectChange = new EventEmitter<Project>(); // TODO: remove

  /** list of projects */
  projects: Project[] = [];

  /** Subject that emits when the component has been destroyed */
  private destroy$ = new Subject<void>();

  /** Currently loaded page */
  private currentPage = 1;

  /** Total number of pages available */
  private totalPages = 1;

  /** Flag to indicate if we're loading data */
  public loading = false;

  /** FormControl for the search keyword */
  public searchCtrl: FormControl = new FormControl();

  /** Subject for the filtered projects observable */
  public filteredProjects: ReplaySubject<Project[]> = new ReplaySubject<
    Project[]
  >(1);

  /** Last search term to avoid duplicate searches */
  private lastSearchTerm: string = '';

  @ViewChild('projectSelect') projectSelect!: MatSelect;
  @ViewChild('searchInput') searchInput!: MatSelectSearchComponent;

  constructor(
    private readonly projectsService: ProjectsService,
    private readonly dialogRef: MatDialogRef<ProjectSelectionDialogComponent>
  ) {}

  ngOnInit() {
    // Load initial data
    this.loadProjects();

    // Listen for search field value changes
    this.searchCtrl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(400), // Wait 400ms after the user stops typing
        distinctUntilChanged(), // Only emit if the value is different from the last one
        tap(() => {
          this.loading = true;
          // Reset pagination when search changes
          this.currentPage = 1;
          this.projects = [];
        }),
        switchMap((searchTerm) => {
          this.lastSearchTerm = searchTerm || '';
          return this.loadProjectsFromApi(searchTerm);
        })
      )
      .subscribe();

    // Listen for project selection changes
    this.projectCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const selectedProject = this.projects.find((p) => p.id === value);
        if (selectedProject) {
          this.projectChange.emit(selectedProject);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Load initial projects
   */
  private loadProjects() {
    this.loading = true;
    this.loadProjectsFromApi().pipe(takeUntil(this.destroy$)).subscribe();
  }

  /**
   * Load projects from API with optional search term
   */
  private loadProjectsFromApi(searchTerm: string = ''): Observable<void> {
    if (this.currentPage > this.totalPages && this.totalPages > 0) {
      this.loading = false;
      return of(void 0);
    }

    // Create params for the API request
    let params = new HttpParams()
      .set('page', this.currentPage.toString())
      .set('per_page', '6');

    // Add search term if provided
    if (searchTerm) {
      params = params.set('name', searchTerm);
    }

    return this.projectsService.fetchProjects(params).pipe(
      takeUntil(this.destroy$),
      tap((response: PaginationContent<Project>) => {
        this.totalPages = response.total_pages;

        // Append new projects to the existing list
        this.projects = [...this.projects, ...response.list];

        // Update the filtered projects
        this.filteredProjects.next(this.projects.slice());
        this.loading = false;
      }),
      finalize(() => (this.loading = false)),
      // Return void to match the return type
      switchMap(() => of(void 0))
    );
  }

  /**
   * Handle scroll to bottom event to load more data
   */
  onScrollToBottom() {
    if (!this.loading && this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loading = true;
      this.loadProjectsFromApi(this.lastSearchTerm)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  /**
   * Clear the search input
   */
  onClear() {
    this.searchCtrl.setValue('');
    this.currentPage = 1;
    this.projects = [];
    this.loadProjects();
  }

  /**
   * Close dialog without submitting
   */
  onNoClick() {
    this.dialogRef.close();
  }

  /**
   * Submit the selected project if valid
   */
  submit() {
    // Mark the control as touched to show validation errors
    this.projectCtrl.markAsTouched();

    if (this.projectCtrl.invalid) {
      return null; // Return null if validation fails
    }

    this.dialogRef.close(this.projectSelect?.value);
  }
}

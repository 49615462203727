import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  TeamFilterService,
  TeamMemberService,
  TeamsService,
  UserService,
} from '../../../core';

@Component({
  selector: 'app-team-filter',
  templateUrl: './team-filter.component.html',
  styleUrls: ['./team-filter.component.scss'],
  standalone: false,
})
export class TeamFilterComponent implements OnInit, OnDestroy {

  @Input() userTeams: Array<{ id: number; name: string }> = [];
  userId?: number;
  selectedTeamId: number | null = null;
  private destroy$ = new Subject<void>();

  constructor(
      private teamMemberService: TeamMemberService,
      private teamService: TeamsService,
      private teamFilterService: TeamFilterService,
      private userService: UserService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.userService.currentUser.subscribe((user) => {
      this.userId = user.id;
    });
    this.teamService.fetchTeams()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.userTeams = data.list.map((team) => ({
          id: team.id,
          name: team.name,
        }));
      });
    this.teamFilterService.selectedTeam$
      .pipe(takeUntil(this.destroy$))
      .subscribe(teamId => {
        this.selectedTeamId = teamId;
      });

  }

  onTeamSelected(teamId: number | null): void {
    this.selectedTeamId = teamId;
    this.teamFilterService.setSelectedTeam(teamId);
  }
}

<div class="px-12 justify-content-center flex-form">
  <form [formGroup]="teamForm" (ngSubmit)="onSubmit()">
    <div class="flex-center-form flex-column">
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'form.teamName.label' | translate }}</mat-label>
        <input matInput formControlName="name">
        @if (teamForm.get('name')?.errors) {
          <mat-error>{{ parseError(teamForm, 'name') }}</mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'form.url.label' | translate }}</mat-label>
        <input matInput formControlName="url">
        @if (teamForm.get('url')?.errors) {
          <mat-error>{{ parseError(teamForm, 'url') }}</mat-error>
        }
      </mat-form-field>
    </div>
    <div>
      <button mat-flat-button color="primary" type="submit" [disabled]="!teamForm.valid">
        {{ 'actions.submit' | translate }}
      </button>
    </div>
  </form>
</div>
